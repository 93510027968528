//
//Directive Note Panel
//-------------------------------------
.note-panel {
  box-sizing: border-box;
  overflow: auto;
  height: 100%;

  .note-input {
    margin: 0 0 5px 0;
  }

  .note-input-field {
    margin: 0 0 5px 0;
  }

  .filter-sorter {
    font-size: 12px;

    .note-panel-filter {
      margin-left: 4px;
    }

    .note-panel-sorter {
      margin-left: 8px;
    }

    .squaredThree {
      margin: 5px auto;
    }

    .dropdown-menu {
      font-size: 12px;
      min-width: 200px;
      li {
        span {
          display: inline-block;
          padding-left: 10px;
        }
      }
    }

    .note-trigger {
      margin-left: 10px;
      color: #428bca;
      &:hover {
        color: #2a6496;
        text-decoration: underline;
      }
    }
  }

}

.extended {

  .filter-input {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .note-panel {
    .size-setter {
      margin-left: 20px;
    }

    .size1 {
      font-size: 16px;
    }
    .size2 {
      font-size: 24px;
    }
    .size3 {
      font-size: 36px;
    }

    .size1, .size2, .size3 {
      &.active {
        text-decoration: underline;
      }
    }
  }
}