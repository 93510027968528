/**
*   All Variables for NavBar
*   prifixes pr = presentr, Nb = Navbar, Mi = Menu icon, Ib= icon bar, C = caret, Dd = Dropdown
*/
@prNbContainerFluidRight: 0px;
@prNbContainerFluidLeft: 0px;

@prNbBackgroundColor: @prColor;

@prNbBorderStyle: none;

@prNbTextColor: @prColorHeader;
@prNbTextColorHover: @prColorHeaderHover;
@prNbBrandGabRight: 10px;
@prNbBrandGabLeft: 15px;

@prNbMiHeight: 40px;
@prNbMiWidth: @prNbMiHeight;
@prNbMiMarginTop: -10px;

@prNbIbLength: 10px;
@prNbIbHeight: 2px;
@prNbIbGab: @prNbIbHeight;
@prNbIbBorderRadius: 1px;

@prNbCLength: 12px;
@prNbCHeight: 8px;

@prNbSeparatorSize: 2px;
@prNbSeparatorColor: @prNbTextColor;

@prNbDdBackgroundColor: #fff;
@prNbDdBackgroundColorHover: @prColorBackgroundContent;

.navbar-inverse {

  background-color: @prNbBackgroundColor;
  border-style: @prNbBorderStyle;

  .container-fluid {
    margin-left: @prNbContainerFluidLeft;
    margin-right: @prNbContainerFluidRight;
  }

  .navbar-brand {
    color: @prNbTextColor;
    padding-right: @prNbBrandGabRight;
    padding-left: @prNbBrandGabLeft;

    .logo {
      display: inline-block;
    }
    .menu-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: @prNbMiHeight;
      width: @prNbMiWidth;
      margin-top: @prNbMiMarginTop;

      .icon-bar {
        display: block;
        height: 0;
        width: 0;
        border-style: solid;
        border-width: @prNbIbHeight @prNbIbLength @prNbIbHeight/2;
        margin: @prNbIbGab;
        border-radius: @prNbIbBorderRadius;
      }
    }

    &.dropdown-toggle {
      .caret {
        border-left-width: @prNbCLength/2;
        border-right-width: @prNbCLength/2;
        border-top-width: @prNbCHeight;
      }
    }
  }

  .navbar-text, .navbar-link {
    color: @prNbTextColor;
  }

  & .navbar-nav {

    .seperator-container {
      margin: 0;
      .separator {
        width: 50px;
        height: 50px;
        margin-left: -19px;
        border-right: solid @prNbSeparatorSize @prNbSeparatorColor;
        border-top: solid @prNbSeparatorSize @prNbSeparatorColor;
        transform: matrix(0.4, 0.5, -0.4, 0.5, 0, 0);
      }
    }

    .active {
      a, a:hover, a:focus {
        background-color: @prNbBackgroundColor;
      }

      .dropdown-menu {
        a {
          text-decoration: none;
          color: @prNbTextColor;
          background-color: @prNbDdBackgroundColor;
          &:hover {
            color: @prNbTextColor;
            background-color: @prNbDdBackgroundColorHover;
          }
        }

      }
    }

    li {
      a {
        color: @prNbTextColor;
        &:hover {
          color: @prNbTextColorHover;
        }
      }
    }
  }

  ul li {
    &.feedback {
      .fa {
        margin-right: 5px;
        //transition: .5s;
      }

      &:hover {
        a {
          color: white;
        }
        .fa {
          //position: relative;
          //top: -30px;
        }
      }
    }
    &.user {
      border-left: solid 1px @prNbTextColor;
      border-right: solid 1px @prNbTextColor;

      .fa {
        margin-right: 3px;
        font-size: 1.2em;
      }

      &.active {

        .no-click{
          a, a:focus {
            background-color: initial;
            text-decoration: none;
          }
          a:hover{
            background-color: initial;
            color: #c4cbd4;
            cursor: initial;
          }
        }

        a, a:focus {
          background-color: darken(@prNbBackgroundColor, 15%);

        }
        a:hover {
          background-color: @prNbBackgroundColor;
        }
      }
    }
  }
  &.is-on-audience {
    @media (max-width: 900px) AND (orientation: landscape) {
      display: none;
    }
  }

  .container-fluid{
    .navbar-collapse{
      .navbar-right{
        .user{
          .no-click{
            &:hover{
              text-decoration: none;
              color: #c4cbd4;
              cursor: default;
            }
            &:focus{
              text-decoration: none;
              cursor: default;
              color: #c4cbd4;
            }
          }
        }
      }
    }
  }
}

#feedbackModal {
  label {
    margin-top: 10px;
  }
  .squaredThree {
    label {
      margin-top: 0;
    }
    margin: 0;
    margin-right: 20px;
    margin-top: 5px;
  }
  #feedbackM {
    min-height: 150px;
  }
  .modal-footer {
    margin-top: 0;
  }
}

@npColor: hsla(hue(@prColor), 60%, 53%, 1);
@npCornerColor: darken(@npColor, 30%);
@npCornerBigSize: 14px;

.navbar-presentr {
  transition: all 0.2s ease;
  .btn {
    border-radius: 0;
  }
  @media (min-width: 768px ) {
    &.on-top {
      height: 70px;
    }
  }
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;

  z-index: 1000;
  &.navbar-blind {
    z-index: 100;
  }
  .navbar-spezial {

    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-group {
      width: 100%;

      justify-content: flex-end;
      padding-right: 0;
      @media (min-width: 768px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: flex;
      }
      .btn.btn-menu-nav {
        background-color: transparent;
        color: #fff;
        border: solid 1px #fff;
        border-radius: 5px;
        width: 3.5em;
        font-size: 1.3em;

        &:hover {
          background-color: hsla(0, 0%, 0%, 0.2);
        }
      }
    }

    &.show-login-modal {
      justify-content: space-between;
    }
    width: 100%;
    background-color: @npColor;
    height: 100%;
    color: white;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.5);
    @media (max-width: 899px) {
      .left-btn {
        margin-left: 80px;
      }
    }
    @media (max-width: 767px) {
      padding: 0 2rem;
    }
    @media (min-width: 768px) {
      padding: 0 40px;
    }
    .left-nav-menu, .right-nav-menu {
      transition: margin 0.1s ease;
      list-style: none;
      margin: 0 0 0 120px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      li {
        margin: 0 0;
        height: 100%;
        display: flex;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          height: 100%;
          &:hover {
            text-decoration: none;
            background-color: hsla(0, 100%, 10%, 0.1);
            span {
              text-decoration: underline;
            }
            i {
              color: white;
            }
          }
          &.active {
            text-decoration: none;
            background-color: hsla(0, 100%, 10%, 0.1);
          }
          i {
            margin-right: 0.5em;
            font-size: 1.2em;
          }
        }
        button, .btn-link-nav {
          padding: 0.4rem 3rem;
          border: solid 1px white;
          border-radius: 5px;
          margin-right: 2em;
          background-color: hsla(0, 100%, 100%, 0);
          height: auto;
          &:hover {
            background-color: hsla(0, 100%, 100%, 0.2);
            color: white;
            text-decoration: underline;
          }
        }
      }
    }
    .right-nav-menu {
      position: absolute;
      right: 3em;
      top: 0;
      margin-left: 80px;
      a {
        padding: 0 2rem;
      }
      .no-click{
        &:hover{
          text-decoration: none;
          cursor: default;
          background-color: transparent;
          span{
            text-decoration: none;
          }
        }
        &:focus{
          text-decoration: none;
          cursor: default;
        }
      }
    }

    .left-nav-menu {
      position: absolute;
      top: 0;

      a {
        @media (min-width: 1200px) {
          padding: 0 4rem;
        }
        @media (max-width: 1199px) {
          padding: 0 2rem;
        }
      }
      &.set-margin {
        margin-left: 0;
      }
    }

    .logo-wrapper {
      transition: all 0.2s ease;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100%;
      z-index: 1;

      @media (min-width: 768px) {
        &.set-mid {
          @media (max-width: 767px) {
            width: ~'calc(100% - 4rem)';
          }
          @media (min-width: 768px) {
            width: ~'calc(100% - 80px)';
          }
          z-index: 1;
          .logo {
            transition: all 0.2s ease;
            width: 74px;
            height: 66px;
            margin-top: 0;
          }
        }
      }

      .logo {
        width: 122px;
        height: 46px;
        margin-top: 0;
        display: none;

        @media (min-width: 768px) {
          &.show-img-top {
            display: block;
          }

          &.show-img-else {
            display: block;
          }
        }

        @media (max-width: 767px) {
          display: block;
          &:first-child {
            display: none;
          }
        }
      }
      .logo-text {
        font-size: 1.3em;
      }

    }

    a {
      position: relative;
      z-index: 2;
      color: white;
      font-size: 1em;
    }
    .btn {
      position: relative;
      z-index: 2;
    }

  }
  .collapsed-header {
    position: fixed;
    top: 50px;
    @media (min-width: 768px) {
      &.is-top {
        top: 70px;
      }
    }
    left: 0;
    right: 0;
    background-color: @npColor;
    height: auto;
    border-bottom: solid 2px #fff;
    ul {
      list-style: none;
      padding: 0;
      li {
        margin: 0;
        padding: 0.5em 0;
        a {
          padding: 0.5em 0.75em;
          font-size: 1.5em;
          color: white;
          &:hover {
            color: lightgrey;
          }
          i {
            margin-right: 0.5em;
          }
        }
        .btn {
          background-color: transparent;
          border: none;
          color: #fff;
          padding: 0.5em 0.75em;
          font-size: 1.5em;
        }
        hr {
          width: 100%;
          height: 1px;
          margin: 0.25em 0;
        }

      }
    }
  }
}