#feedbackModal{
  .modal-body{
    background-color: #eef2f6;
    border: none;
  }

  .form-group{
    .input-group{
      width: 100%;
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: @border-radius-base;
      width: 100%;
      padding-right: 2.5em;
    }

    .dropdown-icon{
      display: block;
      width: 10px;
      height: 10px;
      border-bottom: solid 2px lightgray;
      border-left: solid 2px lightgray;
      transform: rotateZ(-45deg);
      z-index: 4;
      position: absolute;
      right: 16px;
      top: 9px;
      pointer-events: none;
    }

    .switch-label {
      display: block;
    }
  }
}