.presentr-glyphicon-open {
  .glyphicon;
  .glyphicon-plus-sign;
  .clickable
}

.presentr-glyphicon-close {
  .glyphicon;
  .glyphicon-minus-sign;
  .clickable
}

.presentr-glyphicon-play {
  .glyphicon;
  .glyphicon-play;

  &:hover {
    color: red;
  }
}

.nextSlideGlyph {
  .glyphicon;
  .glyphicon-chevron-right;
}

.prevSlideGlyph {
  .glyphicon;
  .glyphicon-chevron-left;

}

.search-glyphicon {
  .glyphicon;
  .glyphicon-search;
}

.sort-by-alphabet-glyphicon {
  .glyphicon;
  .glyphicon-sort-by-alphabet;
}

.sort-by-alphabet-alt-glyphicon {
  .glyphicon;
  .glyphicon-sort-by-alphabet-alt;
}