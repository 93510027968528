@AVcontainerSpaces: 10px;
@AVcontainerHeader: 50px;

@AVcontainerSpacing: 3px;
@AVcontainerColor: hsla(0, 0%, 100%, 1);
@AVcontainerBorderColor: hsla(0, 0%, 70%, 1);
@AVcontainerBorder: solid 1px @AVcontainerBorderColor;

@AVlineHeight: 1.42857143;

@AVcontainerOffset: -1px;

@AVwritingHeight: 0;
//50px;
@AVwritingHeightOpen: 0;
//110px;

@AVpublicWidth: 30px;
@AVpublicWidthOpen: 450px;
@AVpublicWidthBig: 750px;

@AVtransitionSwitchTime: 0.4s;
@AVtransitionOpenPublicTime: 0.3s;

.audience-view {

  .slide-wrap {
    transition: all 0.3s ease;
    position: fixed;
    left: @AVcontainerSpaces;
    top: @AVcontainerHeader + @AVcontainerSpaces;
    right: @AVcontainerSpaces;
    bottom: @AVwritingHeight + @AVcontainerSpaces;

    &.writing-open {
      bottom: @AVwritingHeightOpen + @AVcontainerSpaces;
    }

    &.public-open.public-show {
      right: @AVpublicWidthOpen + @AVcontainerSpaces;
      &.big-cr-wrap {
        right: @AVpublicWidthBig + @AVcontainerSpaces;
      }
    }

    @media (max-width: 900px) AND (orientation: landscape) {
      top: 0;
    }
  }

  .public-wrap {
    transition: all @AVtransitionOpenPublicTime ease;
    background: @AVcontainerColor;
    border: @AVcontainerBorder;

    @media (max-width: 900px) AND (orientation: portrait) {
      position: fixed;
      right: @AVcontainerOffset - @AVpublicWidthOpen + @AVpublicWidth;
      top: @AVcontainerHeader;
      bottom: 0;

      width: @AVpublicWidthOpen - @AVcontainerOffset;
    }
    @media (max-width: 900px) AND (orientation: landscape) {
      position: fixed;
      right: @AVcontainerOffset - @AVpublicWidthOpen + @AVpublicWidth;
      top: 0;
      bottom: 0;

      width: @AVpublicWidthOpen - @AVcontainerOffset;
    }
    @media (min-width: 901px) {
      position: fixed;
      right: @AVcontainerOffset - @AVpublicWidthOpen + @AVpublicWidth;
      top: @AVcontainerHeader + @AVcontainerSpaces;
      bottom: @AVwritingHeight + @AVcontainerSpaces;

      width: @AVpublicWidthOpen - @AVcontainerOffset;
    }

    .opener {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      float: left;
      height: 100%;
      width: @AVpublicWidth;
      border-right: @AVcontainerBorder;

      .switch-cont {
        display: flex;
        justify-content: center;
        align-items: center;

        border-bottom: @AVcontainerBorder;
        background-color: @btn-info-bg;
        color: white;

        width: 100%;
        height: 30px;

      }

      .switch-hide {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
      }

    }

    &.writing-open {
      bottom: @AVwritingHeightOpen + @AVcontainerSpaces;
    }

    .pub-cont {
      padding: 5px;
      display: none;
    }

  }

  .writing-wrap {
    transition: all 0.2s linear;
    position: fixed;
    right: @AVpublicWidthOpen + @AVcontainerSpaces;
    left: @AVcontainerSpaces;
    bottom: @AVcontainerOffset;

    height: @AVwritingHeight; //- @AVcontainerOffset * 2;

    background: @AVcontainerColor;
    //border: @AVcontainerBorder;

    .writing {
      width: 100%;
      height: calc(~"100%" - -1 * unit(@AVcontainerOffset, px));
      padding: 10px;
      outline: none;
      border: none;
      resize: none;
    }
  }

  .open {
    &.writing-wrap {
      height: @AVwritingHeightOpen;
    }

    &.public-wrap {

      @media (max-width: 900px) {
        right: 0;
        width: 100%;
      }
      @media (min-width: 901px) {
        right: @AVcontainerOffset;
        width: @AVpublicWidthOpen - @AVcontainerOffset;
        &.big-cr-wrap {
          width: @AVpublicWidthBig - @AVcontainerOffset;
          .pub-cont {
            width: @AVpublicWidthBig + @AVcontainerOffset - @AVpublicWidth;
          }
        }
      }

      .pub-cont {
        display: flex;
        flex-direction: column;
        height: 100%;
        @media (max-width: 900px) {
          width: ~'calc(100% - 30px)';
        }
        @media (min-width: 901px) {
          width: @AVpublicWidthOpen + @AVcontainerOffset - @AVpublicWidth;
        }

        .back {
          padding: 5px 5px 0 2px;
          overflow: auto;
        }

        .notes {
          transition: all @AVtransitionSwitchTime ease;

          position: absolute;
          top: -1px;
          left: -1px;
          padding: @AVcontainerSpacing;
          height: ~"calc(100% + 2px)";

          border: @AVcontainerBorder;
          border-right: hidden;

          overflow: auto;
          @media (max-width: 900px) {
            width: ~'calc(100% - 30px)';
          }
          @media (min-width: 901px) {
            width: @AVpublicWidthOpen + @AVcontainerOffset - @AVpublicWidth;
          }

          .note {
            padding: 0 5px;
            border-left: 7px solid @btn-success-bg;

            &.speaker {
              border-left: 7px solid @btn-warning-bg;
            }

            &:nth-child(odd) {
              background-color: @prColorOdd;
              &:hover {
                background-color: darken(@prColorOdd, 4%);
              }
            }

            &:nth-child(even) {
              background-color: @prColorEven;
              &:hover {
                background-color: darken(@prColorEven, 4%);
              }
            }

            .user-name {
              font-weight: bold;
            }

          }
        }

        .ars-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          .ar-interactive {
            display: flex;
            height: 100%;
          }
          .ar-wrapper {
            height: auto;
            .filter-group.form-group {
              @media (max-width: 900px) {
                .toggle-big-btn {
                  display: none;
                }
              }
            }
          }
        }

      }

    }
  }

}

.audience-view:fullscreen, .audience-view:-webkit-full-screen {
  background-color: black;

  .slide-wrap {
    left: @AVcontainerSpaces / 2;
    top: @AVcontainerSpaces / 2;
    right: @AVcontainerSpaces / 2;
    bottom: @AVcontainerSpaces / 2;
  }

  .public-wrap {
    position: fixed;
    right: @AVcontainerOffset - @AVpublicWidthOpen + @AVpublicWidth;
    top: 0;
    bottom: 0;
    width: @AVpublicWidthOpen - @AVcontainerOffset;
  }

  .open.public-wrap {

    @media (max-width: 900px) {
      right: 0;
      width: 100%;
    }
    @media (min-width: 901px) {
      right: @AVcontainerOffset;
      width: @AVpublicWidthOpen - @AVcontainerOffset;
      &.big-cr-wrap {
        width: @AVpublicWidthBig - @AVcontainerOffset;
        .pub-cont {
          width: @AVpublicWidthBig + @AVcontainerOffset - @AVpublicWidth;
        }
      }
    }
  }

}
