//
// All Global Classes (Body, Background, Loge etc)
//---------------------------------------------------------------------

body {
  padding-top: 70px;
  overflow-x: hidden;
  padding-bottom: 0;
  background-color: @prColorBackground;

  .toast-top-right {
    top: 76px;
  }

  .black-background {
    background-color: black;
  }
  .btn{
    padding: 6px 10px;
  }

  .btn-hidden {
    background: none;
    padding: 0 0;
  }
}

/* Safari Modal Fix */
body {
  padding-right: 0px !important
}

.modal-open {
  overflow-y: auto;
}
/* --------- */

.logo {
  display: inline-block;
  margin-top: -10px; //ToDo make better withonavt Margin-Top -10
  background-size: 100%;
  background-position: top left;
  width: 25px;
  height: 25px;
}

section {
  @media (max-width: 570px) {
    .container-fluid {
      max-width: 325px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: 571px) and (max-width: 845px) {
    .container-fluid {
      max-width: 575px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: 846px) and (max-width: 1120px) {
    .container-fluid {
      max-width: 850px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: 1121px) and (max-width: 1370px) {
    .container-fluid {
      max-width: 1130px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: 1371px) {
    .container-fluid {
      max-width: 1405px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.loop(@counter) when (@counter > 0) {
  .h@{counter}-inline {
    display: inline-flex;
    padding-top: 0px;
    margin-top: 0px;
    //font-size: @HeaderScaleFactor * (@HeaderScaleFactorMultipilkaor - @counter);
  }
  .loop((@counter - 1)); // next iteration
}

.loop(6);
// launch the loop

.clickable {
  cursor: pointer;
}

.input-no-form {
  display: block;
  height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: @padding-base-vertical @padding-base-horizontal;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid @input-border;
  border-radius: @input-border-radius;
  .box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
  .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
}

.input-no-form-inline {
  .input-no-form;
  display: inline;
}

.input-no-form-header {
  .input-no-form;
  display: inline;
  height: auto;
  font-size: inherit;
}

.slide-container {
  .padding(0);
}

.slide {
  height: 15em;
  border: solid 1px black;
  border-radius: @border-radius-base*2;
  background-color: @prColorBackgroundContent;
  .margin(0.5);
}

hr {
  margin: 0px;
  height: 1px;
  background-color: @prColorBorder;
}

.inline-block {
  display: inline-block;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
  .item {
    flex-grow: 4;
  }
  .text {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.upload {
  height: 0;
  width: 0;
}

.upload-btn {
  .btn;
  .btn-primary;

  &.btn-last {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.line-breaks {
  white-space: pre-wrap;
}

// TODO remove fix
.chevron-fix {
  margin-left: -3px;
}

.slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  //border: solid 1px @prColor;
  &:fullscreen {
    background-color: black;
  }

  &:-webkit-full-screen {
    background-color: black;
  }

  canvas {
    background-color: white;
  }
}

.input-group-addon {
  input[type = "checkbox"] {
    visibility: visible;
  }
}

.center-link {
  text-align: center;
}

.hidden-element {
  visibility: hidden;
}

.add-colon{
  &::after{
    content: ':';
  }
}

.btn {
  &.btn-ghost {
    background: transparent;
    color: @prColor;
    font-weight: bold;
    &:hover {
      background-color: hsla(0, 100%, 100%, 0.5);
      color: black;
    }
  }
}

//
// Needed Later
// ---------------------------------------

/*
.favorit-portrait-switch{
  display: none;
}*/

