@navbarHeight: 50px;
@tabsHeight: 50px;
@SplitBorderColor: darken(@navbar-default-bg, 6.5%);


@widthBig: 100% / 1.618; // Golden ration 1.618
@widthSmall: 100% - @widthBig;


.admin {
  position: fixed;
  top: @navbarHeight;
  left: 0;
  right: 0;
  bottom: 0;

  .page {
    position: absolute;
    top: @tabsHeight;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0 15px 5px 15px;

    display: flex;
    flex-direction: column;

    .header {
      flex-grow: 1;
      max-height: 45px;
      margin-bottom: 10px;
    }
    .body {
      flex-grow: 1;
      display: flex;
      overflow: auto;
      .table-wrap {
        overflow: auto;
        flex-grow: 1;
        width: @widthBig;
        padding-right: 10px;

        .table-header {
          text-align: center;
          font-weight: bold;
          font-size: 1.2em;
          border-bottom: solid 2px black;
          margin-bottom: 5px;
          min-height: 30px;
          .text-left {
            text-align: left;
            padding-left: 10px;
          }
        }
        .table-body {
          .table-row {
            display: flex;
            justify-content: center;
            border-bottom: solid 1px black;
            padding-top: 5px;
            padding-bottom: 5px;
            .text-left {
              justify-content: flex-start;
              padding-left: 10px;
            }
            div {
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
            .position-items{
              display: inline-flex;
              justify-content: space-between;
              padding: 0 20px;
              span, i{
                flex-grow: 1;
                display: inline-flex;
                justify-content: flex-start;
              }
            }

            &:nth-child(even) {
              background-color: @table-bg-accent;
              opacity: 0.9;
            }
            &:nth-child(odd) {
              background-color: @prColorBackground;
              opacity: 0.9;
            }
            &:nth-last-child(1) {
              border-bottom: solid 1px gray;
            }
          }
        }

      }
      .edit {
        flex-grow: 1;
        width: @widthSmall;
        border-left: 2px solid @SplitBorderColor;
        padding-left: 10px;

        .header {
          font-size: 18px;
          font-weight: bold;
          margin: 0 0 5px 0;
        }
        .input-group {
          margin-bottom: 2px;
          .input-group-addon{
            min-width: 170px;
            text-align: right;
          }
          .input-group-btn{
            .btn{
              min-width: 100px;
            }
          }
          select{
            -webkit-appearance: none;
            -moz-appearance: none;
          }
          &.select-group{
            position: relative;
            &:after{
              content: ' ';
              position: absolute;
              width: 10px;
              height: 10px;
              right: 10px;
              top: 10px;
              z-index: 1000;
              border-bottom: 4px solid darken(@SplitBorderColor,5%);
              border-right: 4px solid darken(@SplitBorderColor,5%);
              border-bottom-right-radius: 2px;
              transform: rotate(45deg);
            }
          }
        }

      }
    }

  }

  .page.user {
    .body {
      .edit {
        overflow: auto;
        .create-form{
          .header{
            margin-top: 0;
          }
        }
        .edit-form{
          .header{
            margin-top: 10px;
          }
        }
      }
    }
  }

  .page.portal {
    .body {
      .edit {
        overflow-y: auto;
      }
    }
  }

  .page.presentation {
    .body {
      .edit {
        overflow-y: auto;
      }
    }
  }

  .page.feedback {
    .body {
      .table-wrap{
        width: 50%;
      }
      .edit {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .form-control {
          flex-grow: 2;
          margin-bottom: 5px;
        }
      }
    }
  }

  .positive-fa {
    color: @btn-success-bg;
    font-size: 1.7em;
  }
}

/* Small Workaround for the resetPassword Modal (only Used in the admin directive) */
#resetPasswordAdminModal{
  .input-group {
    margin-bottom: 2px;
    .input-group-addon{
      min-width: 170px;
      text-align: right;
    }
    .input-group-btn{
      .btn{
        min-width: 100px;
      }
    }
    select{
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    &.select-group{
      position: relative;
      &:after{
        content: ' ';
        position: absolute;
        width: 10px;
        height: 10px;
        right: 10px;
        top: 10px;
        z-index: 1000;
        border-bottom: 4px solid darken(@SplitBorderColor,5%);
        border-right: 4px solid darken(@SplitBorderColor,5%);
        border-bottom-right-radius: 2px;
        transform: rotate(45deg);
      }
    }
  }
}