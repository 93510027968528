.caretUp {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 10px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.caretDown {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 10px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

//Note Panel
.sort-by {
  margin-left: 10px;
}

.sort-by-gly {
  font-size: 11px;
}

.default-cursor {
  cursor: default;
}

// FOR AR's
.error{

  border-color: @btn-danger-bg;
  border-width: 2px;

}