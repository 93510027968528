#tuRegisterModal{
  .modal-header{
    h4{
      font-size: 1.5em;
    }
  }
  .modal-body{
    padding:0;
    .part-one{
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      a:hover, a:visited, a:link, a:active {
        text-decoration: none;
      }

      .header {
        color: #333;
      }

      .body {
        color: #333;
      }

      &.row.disabled{
        opacity: 1;
        .header{
          opacity: 0.4;
        }
        .body{
          opacity: 0.4;
        }
        .footer{
          opacity: 0.3;
        }
      }

      .checked{
        display:none;
        color: @btn-success-bg;
        font-size: 7em;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        padding-top: 0.5em;
        padding-bottom: 0.1em;


        &.visible{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .part-two{
      border: solid 1px lightgrey;
      border-left: none;
      border-right: none;
      .footer{
        margin-top: 1em;
      }
    }
    .part-three{
      font-size: 0.9em;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .row.disabled {
      opacity: 0.3;
    }
    .footer {
      padding-bottom: 2em;
    }
  }
  .row{
    margin: 0 0;
    padding: 1.5em;
    .header{
      h5{
        font-size: 1.4em;
      }
    }
    .body{
      font-size: 1.2em;
    }
    .footer{
      .tu-logo{
        width: 60px;
        height: 60px;
        margin: 0 2em;
      }
    }
    &.clickable:hover{
      background-color: #eee;
    }
  }

  .modal-footer{
    padding-top:0;
    .row{
      .body{
        font-size: 0.9em;
      }
    }
  }
}