@overlayBorderWidth: 1px;
@overlayBorderColor: black;
@overlayAnswerBackground: hsla(hue(@prColor), 50%, 50%, 0.4);
@overlayAnswerBackgroundCorrect: hsla(120, 39%, 54%, 0.4);
@overlayProgressBarBGColor: white;
@overlayProgressBarColor: black;
@overlayProgressBarBorder: solid 1px grey;
@overlayProgressBarBorderRadius: 4px;

.overlay {
  width: 100%;
  height: 100%;
  .ar-header {
    margin: 0 1em;
    .question {
      font-size: 2em;
    }
    .description {
      font-size: 1.5em;
      overflow: hidden;
    }
    .hide-progress {
      opacity: 0;
    }
  }
  .ar-body {
    .form-group {
      margin: 0 2em 20px 2em;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .answer-wrapper {
        position: relative;
        margin-bottom: 0;
        margin-top: 10px;

        border-style: solid;
        border-width: @overlayBorderWidth;
        border-color: transparent transparent @overlayBorderColor @overlayBorderColor;

        .check-symbol {
          align-self: center;
          .wrong {
            color: @btn-danger-bg;
          }
          .right {
            color: @btn-success-bg;
          }
          .pr-stack {
            width: 1.5em;
            height: 1.5em;
            line-height: 1.5em;
            .pr-square-o {
              top: 1px;
              font-size: 1.5em;
            }
            .pr-circle-o {
              top: 0;
              font-size: 1.5em;
            }
            .pr-square, .pr-circle {
              transition: font-size 0.2s ease;
              font-size: 0;
            }
            &.select {
              .pr-square {
                font-size: 0.9em;
              }
              .pr-circle {
                font-size: 0.9em;
              }
            }
          }
        }

        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          position: relative;

          z-index: 3;
          .count {
            align-self: center;
            margin-right: 1em;
          }
        }
        .center-text {
          display: flex;
          align-items: center;
          height: 100%;
          flex-grow: 1;
        }
        .answer-text {
          margin: 0 1em;
          overflow: hidden;
          max-height: 100%;
          word-break: break-all;
        }
      }
      progress[value] {
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        background: none;

        height: 100%;
        width: 100%;
        border: none;

        &::-webkit-progress-bar {
          background: none;
        }

        &::-moz-progress-bar {
          background-color: @overlayAnswerBackground;
        }

        &::-webkit-progress-value {
          background-color: @overlayAnswerBackground;
        }

        &.correct-answer{
          &::-moz-progress-bar {
            background-color: @overlayAnswerBackgroundCorrect;
          }

          &::-webkit-progress-value {
            background-color: @overlayAnswerBackgroundCorrect;
          }
        }

      }
    }
  }
}

.overlay.quiz-overlay {
  .ar-header {
    .progress{
      margin: 0 1rem;
      height: 3em;
      .progress-bar{
        font-size: 2.5em;
        &.progress-bar-trans{
          background-color: @overlayProgressBarBGColor;
          color: @overlayProgressBarColor;
          border: @overlayProgressBarBorder;
          border-radius: @overlayProgressBarBorderRadius;
          .progress-bar-text-right{
            justify-content: center;
          }
        }

        .progress-bar-text-left, .progress-bar-text-right {
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          height: 100%;
          i {
            margin: 0 0.5em;
          }
        }

        .progress-bar-text-left {
          justify-content: flex-start;
        }

        .progress-bar-text-right {
          justify-content: flex-end;
        }
      }
    }
  }
}

.overlay.open-question-overlay {
  .answer-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-wrap: wrap;
    height: 100%;
    margin: 0 0.5em;
    .open-answer {
      font-size: 20px;
      line-height: 1.3;
      width: 33%;
      margin-right: 3px;
      border:none;
    }

  }
}

