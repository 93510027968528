/**
*   All Variables for Portal Overview
*   prifixes pr = presentr, Po = Portal Overview
*/

@prPoDragOverBorderRadius: 5px;
@prPoDragOverShadowSize: 30px;
@prPoDragOverBackgroundColor: hsla(hue(@prColor), 70%, 25%, 0.2);

@prPoHeaderContentSizeL: 25%;
@prPoHeaderContentSizeS: 50%;

@prPoPlMinHeight: 50px;
@prPoPlPaddingBottom: 10px;
@prPoPlDeleteColor: red;

@prPoPiEditGlyphSize: 1.5em;
@prPoPiEditGlyphMargin: 5px;

@prPoPiPlayGlyphSize: 2.5em;
@prPoPiPlayGlyphBorderType: Solid;
@prPoPiPlayGlyphBorderColor: black;
@prPoPiPlayGlyphBorderWidth: 1px;
@prPoPiPlayGlyphBorderRadius: @standardBoarderRadius;
@prPoPiPlayGlyphPadding: @standardBoarderPadding;

@prPoPiPlayGlyphHoverColor: red;
@prPoPiPlayGlyphHoverBackgroundColor: @prColorBackgroundContent;
@prPoPiPlayGlyphHoverFontSize: 2.5em;

@prPoPiUploadShadowSize: 20px;
@prPoPiUploadShadowColor: @prColor;
@prPoPiDragOverUploadBackgroundColor: @prPoDragOverBackgroundColor;

.portal-overview {

  .row.alert-danger{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.1em;
    padding: 1em 0;
    margin: 0.5em 0;
    border-radius: @border-radius-base;
    span{
      margin-bottom: 0.5em;
    }
    .btn {
      width: 250px;
      padding: 2px;
      font-size: 1em;
    }
  }

  .h2-inline {
    &.stretch-width {
      width: 100%;
    }
    .input-group {
      width: 55%;
      min-width: 320px;
      position: relative;
      bottom: 6px;
      input {
        height: auto;
        font-size: 23px;
      }
      .input-group-btn {
        .btn {
          font-size: 23px;
        }
      }
    }
  }

  .rename-title-form {
    display: inline-block;
    position: relative;
    top: 4px;
  }

  .create-input {
    margin-top: 25px;
  }

  // ToDo Refactor -----
  .wrong {
    outline-color: red;
    border-color: red;
  }
  .pretty-url {
    margin-left: 10px;
  }
  // END ---------------

  .over {

    .clickable;
    border-radius: @prPoDragOverBorderRadius;
    box-shadow: 0 0 @prPoDragOverShadowSize @prPoDragOverBackgroundColor;
    background-color: @prPoDragOverBackgroundColor;

  }

  .portal-overview-header {
    display: flex;
    align-items: center;
  }

  @media (max-width: 991px) {
    .left-content {
      width: @prPoHeaderContentSizeS;
    }

    .right-content {
      width: @prPoHeaderContentSizeS;
    }
  }
  @media (min-width: 992px) {
    .left-content {
      width: @prPoHeaderContentSizeL;
    }

    .right-content {
      width: @prPoHeaderContentSizeL;
    }
  }

  .left-content {
    display: flex;
    align-items: center;
    float: left;
    span {
      font-size: 18px;
      margin-left: 5px;
    }

    .btn.btn-success {
      div { // Workaround for Firefox because
        display: flex;
        align-items: center;
      }
    }
    .btn.btn-ghost {
      margin-left: 0.5em;
      border: solid 1px #333;
      border-radius: @border-radius-base;
      div { // Workaround for Firefox because
        display: flex;
        align-items: center;
        font-weight: normal;
      }
      .fa {
        font-size: 1.4em;
      }
    }

  }

  .middle-content {
    width: @prPoHeaderContentSizeS;
    float: left;
  }

  .right-content {
    float: left;

    .file-upload-btn {
      width: 100%;
      .upload {
        height: 0;
        width: 0;
        float: right;
      }

      // Styles the Upload Button
      .upload-btn {
        float: right;
        .btn;
        .btn-primary;
      }

      div {
        &.upload-btn {
          font-size: 18px;
        }
      }

    }
    .fileUpload {
      position: relative;
      overflow: hidden;
      margin: 10px;
    }

    .fileUpload input.upload {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }

  .upload-area {
    z-index: 1;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 @prPoPiUploadShadowSize @prPoPiUploadShadowColor;

    &.over {
      border-radius: 0;
      background-color: @prPoPiDragOverUploadBackgroundColor;
    }
  }

  .dropdown-menu {
    li {
      margin: 0 2px 3px 6px;
      &:hover {
        background-color: lightgrey;
      }
    }
  }

  .create-info {
    font-size: 40px;
    margin-top: 20px;
    span {
      margin-left: 40px;
    }
  }

  .portal-list {
    margin-top: 2em;
    display: flex;
    flex-direction: column-reverse;
  }

  .presentation-item {
    .wrapper {
      .presentation-container {
        .img {
          .running {
            &.active {
              display: none;
            }
          }
        }
        .action {
          .speaker {
            display: block;
          }
        }
      }
    }
  }

}