@pPageMediaL: 1400px;
@pPageMediaLM: 1399px;
@pPageMediaM: 1100px;
@pPageMediaMS: 1099px;

@pPagePrintWidth: 210mm;
@pPagePrintBackgroundColor: white;
@pPagePrintBorder: 1px solid #555;
@pPagePrintBorderHeader: 2px solid;

@pPagePrintSlideWide: 10%;
@pPagePrintTypeWide: 15%;
@pPagePrintQuestionWide: 65%;

@pPagePrintElementBorder: 1px solid transparent;
@pPagePrintElementBorderColor: #555;

@pPageStandardMargin: 0.5em;

.mixin-table-header {
  flex-grow: 1;
  text-align: center;
  color: @pPagePrintElementBorderColor;
}

.mixin-table-element {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.mixin-pp-responsive {
  @media (min-width: @pPageMediaL) {
    margin: 0 auto;
  }
  @media (min-width: @pPageMediaM) AND ( max-width: @pPageMediaLM) {
    margin: 0 @pPageStandardMargin;
  }
  @media (max-width: @pPageMediaMS) {
    margin: 0 auto;
  }
}

.print-page-container {
  display: flex;
  @media (max-width: @pPageMediaLM) {
    flex-direction: column;
  }
  .info-no-ar-wrapper {
    font-size: 2em;
    height: ~'calc(100vh - 70px)';
    width: @pPagePrintBorder;
    display: flex;
    align-items: center;
    .mixin-pp-responsive;
  }
  .print-container {
    border: @pPagePrintBorder;
    background-color: @pPagePrintBackgroundColor;
    width: @pPagePrintWidth;
    overflow: auto;
    padding: @pPageStandardMargin * 2;

    .info-no-ready-ar-wrapper {
      display: flex;
      justify-content: center;
      font-size: 1.5em;
      margin: @pPageStandardMargin * 2;
    }
    .print-header {
      .print-logo {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-bottom: @pPagePrintBorderHeader;
        padding-bottom: @pPageStandardMargin;
        h2 {
          margin: 0 0 0 -7px;
          line-height: 1;
        }
        .img-container {
          padding-top: 10px;
          margin-right: 0.5em;
          .logo {
            width: 33px;
            height: 33px;
          }
        }
      }
      .print-list-info-element {
        display: flex;
        font-size: 1.35em;
        align-items: flex-end;
        margin-top: @pPageStandardMargin * 0.5;
        border-bottom: @pPagePrintBorderHeader;
        padding-bottom: @pPageStandardMargin * 0.5;
        .start-slide-num {
          .mixin-table-header;
          width: @pPagePrintSlideWide;
        }
        .type {
          .mixin-table-header;
          width: @pPagePrintTypeWide;
        }
        .question {
          .mixin-table-header;
          width: @pPagePrintQuestionWide;
        }
      }
    }
    .print-body {
      .print-list-element {
        display: flex;
        font-size: 1.2em;
        margin: @pPageStandardMargin @pPageStandardMargin * 0.5 0 @pPageStandardMargin * 0.5;
        padding-bottom: @pPageStandardMargin;

        border: @pPagePrintElementBorder;
        border-bottom-color: @pPagePrintElementBorderColor;

        .ar-start-slide {
          .mixin-table-element;
          width: @pPagePrintSlideWide;
          justify-content: flex-start;
          .start-slide-num, .no-start-slide-num {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
          }
        }
        .ar-type {
          .mixin-table-element;
          width: @pPagePrintTypeWide;
          justify-content: flex-start;
          span {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }
        .ar-question {
          .mixin-table-element;
          width: @pPagePrintQuestionWide;
          padding-left: @pPageStandardMargin;
        }
      }
    }
    .mixin-pp-responsive;
  }
  .option-container {
    border: @pPagePrintBorder;
    background-color: @pPagePrintBackgroundColor;
    padding: @pPageStandardMargin @pPageStandardMargin @pPageStandardMargin * 1.5 @pPageStandardMargin * 1.5;
    display: flex;

    .option-header {
      h2 {
        padding: 0;
        margin: 0;
      }
    }
    .option-body {
      margin: @pPageStandardMargin * 3 @pPageStandardMargin;
      .bs-switch-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
    .option-footer {
      margin: 0 @pPageStandardMargin;
      .btn-group {
        .btn {
          font-size: 1.5em;
        }
      }
    }

    // MEDIA QUERRYS
    @media (min-width: @pPageMediaL) {
      position: fixed;
      flex-direction: column;
      right: 0;
      width: ~'calc((100vw - 210mm) / 2 - 1.5em)'; // ADD Variables @pPagePrintWidth + @pPageStandardMargin
      .option-body {
        .btn-group {
          margin-bottom: @pPageStandardMargin;
        }
      }
    }
    @media (min-width: @pPageMediaM) AND ( max-width: @pPageMediaLM) {
      position: fixed;
      flex-direction: column;
      right: 0;
      width: ~'calc(100vw - 215mm - 1em)'; // ADD Variables @pPagePrintWidth + @pPageStandardMargin * 2
      .option-body {
        .btn-group {
          margin-bottom: @pPageStandardMargin;
        }
      }
    }
    @media (max-width: @pPageMediaMS) {
      flex-direction: row;
      width: @pPagePrintWidth;
      margin: -21px auto @pPageStandardMargin auto;
      justify-content: space-between;
      padding: 0.1cm 0.5cm;
      .option-header {
        display: none;
      }

      .option-body {
        display: flex;
        margin: @pPageStandardMargin;
        .btn-group {
          margin-right: @pPageStandardMargin * 2;
        }
      }

      .option-footer {
        align-self: center;
      }
    }
  }

  @media print {
    .print-container {
      border: none;
      width: 100%;
      position: absolute;
      padding: 0;
      top: 5mm;
      .print-list-element {
        page-break-inside: avoid;
      }
    }

    .option-container {
      display: none;
    }
  }
}

