/**
*   The Feedback-Row-Item need 3 Elements a fb-header, a hr and a fb-body
*   the header takes as many items as space is left
*   the body just have text
*   feedback-row-item ist 100% width and take as much height as it needs
*   if u add the friendly class to an item in the header it will get Bold and the color Green
*
**/

.feedback-row-item {
  margin-bottom: 10px;
  .fb-header {
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .friendly {
      color: @btn-success-bg;
      font-weight: bold;
    }
    .close, .open {
      color: grey;
      opacity: 1;
      &:hover {
        color: black;
      }
    }
    i{
      flex-shrink: 1;
      margin-right: 5px;
    }
    small{
      flex-shrink: 1;
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
    }
    .fb-user{
      flex-shrink: 1;
      margin-right: 5px;
      font-weight: bold;
    }
    .fb-mail{
      flex-grow:1;
      margin: 0 5px;
    }
  }
  hr {
    background-color: hsla(hue(@prColor), 7%, 84%, 1);

    &.friendly {
      background-color: @btn-success-bg;
      height: 1px;
    }
  }
  .fb-body {
    white-space: pre-line;
    padding: 0 7px;
  }
}