.beamer-content {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;

  .size-container {
    .beamer-slide {
      position: absolute;
    }
    .drawing-overlay {
      pointer-events: none;
      position: absolute;
      &.drawing{
        pointer-events: all;
      }
    }
    .answer-overlay {
      position: absolute;
      background-color: white;
    }
  }

  slide-controls {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
    margin-right: 15px;
    width: auto;

    .btn-group {
      position: relative;
      z-index: 200;
    }

    .beamer-header {
      position: fixed;
      left: 0;

      z-index: 100;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 8vh;

      font-size: 6vh;
      color: white;

      background-color: hsla(hue(@prColor), 32%, 34%, 0.8);

      &.closed {
        display: none;
      }

      .plugins {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        font-size: 4vh;

        display: flex;
        align-items: center;

        color: lightgrey;

        .symbol {
          &:hover {
            color: white;
          }

          &:first-child {
            margin: 0 20px;
          }
        }

      }

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }

    }
  }
}

.beamer-slide {
  background-color: @prColorBackgroundContent;
}

.beamer-draw-menu {
  border: solid 1px #000;
}

