.modal-dialog {
  .modal-content {
    background-color: @prColorBackground;
    .modal-header{
      border:none;
    }
    .modal-footer{
      border:none;
    }
    .modal-body {
      background-color: white;
      margin: 0 15px 15px;
      border-radius: 4px;
      border: solid 1px @navbar-default-border;
      .form-group:last-child {
        margin-bottom: 0;
      }
      padding: 0.5em;
      //border: solid 1px #ccc;
      .flex-row {
        width: 100%;
        display: flex;
        margin-left: 20px;
        white-space: pre;
        &.header {
          margin-left: 0;
          font-weight: bold;
        }
      }
      .button-row {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
}

