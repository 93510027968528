/**
*   the class 'tabs' is a navigation on a page it's high is 50px
*   in a tabs unit you have to use a ul element with the class tab-nav
*   an for each tab you add a li element with a 'a' (link) element.
**/

@tabsHeight: 50px; // sets the height of the tab
@tabBorderColor: darken(@navbar-default-bg, 6.5%); // sets the border color of the tab-nav
@tabActiveBorderColor: @prColor; // sets the border color of the tab-nav

.tabs{
  position: absolute;
  top:0;
  left: 0;

  height: @tabsHeight;
  width: 100%;

  .tab-nav{
    margin: -1px 10px 0 10px;
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;


    li{
      display: flex;
      justify-content: center;
      align-items: center;

      border: solid 1px @tabBorderColor;
      border-left: none;
      border-right:none;
      flex-grow: 1;

      min-height: 30px;

      &:first-child{
        border-left: solid 1px @tabBorderColor;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-right: solid 1px @tabBorderColor;
        border-bottom-right-radius: 5px;
      }

      &.active{
        border: 1px solid @tabActiveBorderColor;
        margin-bottom: -10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: white;
        &:hover{
          a{
            text-decoration: none;
          }
        }
      }

      a{
        color: @text-color;
      }

      &:hover{
        a{
          text-decoration: underline;
        }
      }

    }
  }

}