/* entire container, keeps perspective */
.flip-container {
  perspective: 1000;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
}

/*  UPDATED! flip the pane when hovered */
.flip-container.flipped .back {
  transform: rotateY(0deg);
}
.flip-container.flipped .front {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 100%;
  height: 100%;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;

  height: 100%;
  width: 100%;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  transition: 0.6s;
  transform-style: preserve-3d;

  position: absolute;
  top: 0;
  left: 0;
}

/*  UPDATED! front pane, placed above back */
.front {
  z-index: 2;
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(-180deg);
}
