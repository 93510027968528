@EVcontainerSpacing: 10px;
@EVcontainerOffset: -1px;
@EVcontainerBackgroundColor: hsla(0, 0%, 100%, 1);
@EVcontainerBorderColor: hsla(0, 0%, 70%, 1);
@EVcontainerBorder: solid 1px @EVcontainerBorderColor;

@EVlineHeight: 1.42857143;

@EVnavbarHeight: 50px;

@EVinfoWrapWidth: 170px;
@EVinfoWrapHeight: 100px;
@EVinfoWrapNumbersMargin: -10px;
@EVinfoWrapBackgroundColor: lightyellow;

@EVtimeWrapWidth: 270px;
@EVtimeWrapHeight: @EVnavbarHeight + @EVcontainerSpacing + 2px + @EVinfoWrapHeight;
@EVtimeWrapFontSize: 6em;

@EVcrWrapWidth: 750px;

@EVanswerWidth: 50%;
@EVcountWidth: 10%;

@EVnoteWrapLines: 3;
@EVnoteWrapFontSize: 36px;

@EVnoteWrapExtraSpace: 5px;

.extended-view {

  .slide-wrap {
    transition: all 0.3s ease;
    position: fixed;
    top: @EVnavbarHeight + @EVcontainerSpacing;
    left: @EVcontainerSpacing;
    right: @EVcontainerOffset + 2 * @EVcontainerSpacing + @EVinfoWrapWidth + @EVtimeWrapWidth;
    bottom: @EVnoteWrapLines * (@EVnoteWrapFontSize * @EVlineHeight) + @EVnoteWrapExtraSpace + @EVcontainerSpacing;

    &.open {
      bottom: @EVcontainerSpacing;
    }

    &.big-cr-wrap {
      right: @EVcontainerOffset + @EVcontainerSpacing + @EVcrWrapWidth;
    }

  }

  .info-wrap {
    position: fixed;
    top: @EVnavbarHeight + @EVcontainerSpacing / 2;
    right: (@EVcontainerOffset * 2) + @EVcontainerSpacing + @EVtimeWrapWidth;

    width: @EVinfoWrapWidth;
    height: @EVinfoWrapHeight + @EVcontainerSpacing / 2;

    background-color: @EVinfoWrapBackgroundColor;
    border: @EVcontainerBorder;

    display: flex;
    flex-direction: column;

    .line {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: @EVinfoWrapHeight / 2;

      i {
        width: 50px;
        display: flex;
        justify-content: center;
      }
      .numbers {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        align-items: flex-end;
      }
      .number {
        font-size: 3.5em;
        margin-bottom: -0.05em;
      }
      .small-number {
        font-size: 2.8em;
      }
      .info {
        font-size: 1.7em;
      }
    }

  }

  .time-wrap {
    position: fixed;
    right: @EVcontainerOffset;
    top: @EVnavbarHeight + @EVcontainerOffset;

    width: @EVtimeWrapWidth;
    height: @EVtimeWrapHeight - @EVnavbarHeight;

    background-color: @EVcontainerBackgroundColor;
    border: @EVcontainerBorder;

    display: flex;
    justify-content: center;
    align-items: center;

    .time {
      font-weight: bold;
      font-size: @EVtimeWrapFontSize;
    }
  }

  .cr-wrap {
    transition: all 0.3s ease;
    position: fixed;

    top: @EVnavbarHeight + @EVinfoWrapHeight + 2 * @EVcontainerSpacing;
    right: @EVcontainerOffset;
    bottom: @EVcontainerSpacing;

    width: @EVcontainerOffset + @EVcontainerSpacing + @EVinfoWrapWidth + @EVtimeWrapWidth;

    background-color: @EVcontainerBackgroundColor;
    border: @EVcontainerBorder;

    overflow: auto;

    padding: 3px 2px 3px 5px; // ToDo Abhängig von .cr-element padding, auslagern in Variable.

    &.big-cr-wrap {
      top: @EVnavbarHeight - 1;
      width: @EVcrWrapWidth;
    }

    &.animated {
      animation-name: loading;
      animation-duration: 2.5s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }

    input[type="checkbox"] {
      visibility: visible;
    }

    .cr-element {
      display: flex;
      padding: 3px 5px;
      margin: 2px 0;
      width: 100%;

      &.start-cr {

        animation-name: blink;
        animation-duration: 2.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        animation-play-state: running;
        .second-col {
          .btn-success {
            animation-name: btn-blink;
            animation-duration: 2.5s;
            animation-timing-function: ease-out;
            animation-iteration-count: infinite;
            animation-play-state: running;
          }
        }
      }

      .left-col {
        display: inline-flex;
        .btn-group {
          margin-bottom: 0;
        }
        .dropdown-menu li a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          i {
            width: 25px;
            text-align: center;
          }
        }
      }

      .right-col {
        width: 100%;
        display: inline-flex;
        margin-left: 15px;
        margin-right: 5px;
      }

      .first-col {
        display: inline-block;
        font-size: 20px;
        width: 75%;
        padding-right: 5px;
        overflow-y: hidden;
        height: 20px * 1.4;

        &.full-question {
          height: auto;
          padding-left: 32px;
          text-indent: -16px;
        }

        i {
          font-size: 16px;
        }
      }
      .second-col {
        display: inline-flex;
        font-size: 13px;
        width: 28%;
        align-self: center;
        justify-content: flex-end;
      }

      &:nth-child(even) {
        background-color: hsla(hue(@prColor), 20%, 80%, 0.2);
      }

      .min-size {
        min-width: 95px;
      }
    }

  }

  .note-wrap {
    transition: all 0.3s ease;
    position: fixed;
    bottom: @EVcontainerOffset;
    right: @EVcontainerOffset + 2 * @EVcontainerSpacing + @EVinfoWrapWidth + @EVtimeWrapWidth;
    left: @EVcontainerSpacing;

    height: @EVnoteWrapLines*( @EVnoteWrapFontSize * @EVlineHeight) + @EVnoteWrapExtraSpace;

    font-size: @EVnoteWrapFontSize;

    border: @EVcontainerBorder;
    background-color: @EVcontainerBackgroundColor;
    padding: 10px;

    overflow: auto;

    &.close {
      height: 0;
      padding: 0;
    }

    .note {
      background-color: @EVcontainerBackgroundColor;
    }

    &.big-cr-wrap {
      right: @EVcontainerOffset + @EVcontainerSpacing + @EVcrWrapWidth;
    }

  }

  .ff-render-workaround {
    width: 100%;
    border-bottom: solid white 1px;
  }

}

@keyframes loading {
  0%, 100% {
    border-color: @btn-success-bg;
    box-shadow: 0 0 20px 2px @btn-success-bg;
  }
  50% {
    border-color: @btn-success-bg;
    box-shadow: 0 0 5px 2px @btn-success-bg;
  }
}

@keyframes blink {
  50% {
    background-color: lighten(@btn-success-bg, 50%);
  }
  0%, 100% {
    background-color: lighten(@btn-success-bg, 25%);
  }
}

@keyframes btn-blink {
  50% {
    background-color: lighten(@btn-success-bg, 15%);
    border-color: lighten(@btn-success-border, 15%);
  }
  0%, 100% {
    background-color: @btn-success-bg;
    border-color: @btn-success-border;
  }
}