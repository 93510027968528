@prColorOverlay : hsla(hue(@prColor), 50%, 30%, 0.5);

.portal {

  .portal-header {
    margin-bottom: 25px;
    font-weight: bold;
  }

  .presentation-item {
    .wrapper {
      height: 260px;
      .presentation-container {
        .img{
          .clickable;
        }
        .info .date {
          bottom: 5px;
        }
        .action {
          display: none;
        }
        .foremost{
          display: none;
        }
      }
    }
  }

  .active {
    .presentation-container {
      .wrapper {
        background-color: green;
      }
    }
  }

  .portal-error {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
  }
}
