/**
*   All Variables for Presentation Item
*   prifixes pr = presentr, Pi = presentation item, Pc = Presentation Container, Sw = Size Wrapper,
*/

@prPiWrapperBackgroundColor: white;
//@prColorBackgroundContent;
@prPiWrapperBorderRadius: 0;
//@standardBoarderRadius;
@prPiWrapperBorderColor: hsla(hue(@prColor), 7%, 84%, 1);
//@prColor;
@prPiWrapperBorderType: solid;
@prPiWrapperBorderWidth: 1px;
@prPiWrapperMargin: 25px;
@prPiWrapperPadding: @standardBoarderRadius;
@prPiWrapperWidth: 250px;
@prPiWrapperRatio: 120%;

@prPiRunningGlyphSize: 4em;
@prPiRunningGlyphColor: @btn-success-bg;
@prPiRunningBackgroundColor: hsla(0, 100%, 0%, 0.3);

@prPiPcMargin: 0px;

@prPiPcContPadding: 10px;
@prPiPcContWidth: 100%;

@prPiPcImgHeight: 187px;
@prPiPcImgWidth: @prPiPcContWidth;
@prPiPcImgBackgroundColor: @prPiWrapperBackgroundColor;

@prPiPcInfoWidth: @prPiPcContWidth;
@prPiPcInfoTitleSize: 75%;

@prPiPcActionHeight: 45px;
@prPiPcActionWidth: @prPiPcContWidth;
@prPiPcActionFontSize: 2em;
@prPiPcActionHover: red;

@prPiPcActionBothMargin: 0px 0px 0px 20px;
@prPiPcActionOtherMargin: 5px;

@linkColor: hsla(207, 55%, 52%, 1);
@linkColorHover: darken(@linkColor, 20%);

.presentation-item {
  padding: 0;

  .wrapper {
    position: relative;
    margin-left: @prPiWrapperMargin;
    margin-bottom: @prPiWrapperMargin;
    background-color: @prPiWrapperBackgroundColor;
    border: @prPiWrapperBorderType @prPiWrapperBorderWidth @prPiWrapperBorderColor;
    border-radius: @prPiWrapperBorderRadius;
    float: left;
    width: @prPiWrapperWidth;

    .ratio {
      padding-bottom: @prPiWrapperRatio;
    }

    .presentation-container {
      position: absolute;
      top: @prPiPcMargin;
      bottom: @prPiPcMargin;
      left: @prPiPcMargin;
      right: @prPiPcMargin;

      .img {
        position: relative;
        height: @prPiPcImgHeight;
        width: @prPiPcImgWidth;
        background-color: @prPiPcImgBackgroundColor;
        border-bottom: @prPiWrapperBorderType @prPiWrapperBorderWidth @prPiWrapperBorderColor;
        border-top-left-radius: @prPiWrapperBorderRadius;
        border-top-right-radius: @prPiWrapperBorderRadius;

        display: flex;
        align-items: center;
        justify-content: center;

        .presentation-menu {
          position: absolute;
          top: 0;
          right: 0;
          width: 2.3em;
          height: 2.3em;

          .fa-fw {
            width: 2em;
          }
          .btn {
            color: lighten(#000, 5%);
            background-color: rgba(255, 255, 255, 0);
            font-size: 1.7em;
            width: 100%;
            height: 100%;
            cursor: pointer;
            &:hover, &:focus {
              background-color: rgba(255, 255, 255, 0.25);
              color: black;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }

            &:focus {
              border: solid 1px grey;
            }
          }
        }

        .pres-thumbnail {
          border-top-left-radius: @prPiWrapperBorderRadius;
          border-top-right-radius: @prPiWrapperBorderRadius;
          max-width: 248px;
          max-height: 100%;
          object-fit: contain;
        }

        .running {
          display: none;
          font-size: @prPiRunningGlyphSize;
          color: @prPiRunningGlyphColor;
          background-color: @prPiRunningBackgroundColor;

          &.active {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }

        .foremost {
          position: absolute;
          top: 0.1em;
          left: 0.3em;
          color: @btn-warning-bg;
          font-size: 30px;
        }

        .visibility {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(0, 0, 0, 1);
          font-size: 30px;
          border-bottom-right-radius: 4px;
          padding: 5px;
        }

        .delete-overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .delete-text {
            font-size: 1.5em;
            font-weight: bold;
          }

          .btn-groups {
            margin-top: 2em;
            display: flex;
            justify-content: space-around;
            width: 100%;
          }
        }

      }

      &.private {
        background-color: darken(white, 4%);
        .img {
          background-color: darken(white, 4%);
          img {
            opacity: 0.5;
          }
          .foremost {
            opacity: 0.8;
          }
          .visibility {
            background: none;
          }
        }
        .info {
          opacity: 0.8;
        }
        .action {
          opacity: 1;
          background-color: white;
        }
      }

      .info {
        width: @prPiPcInfoWidth;
        box-sizing: border-box;
        padding: @prPiPcContPadding/2 @prPiPcContPadding;

        .title {
          margin: 0;
          padding: 0;
          font-weight: bold;
          overflow-x: hidden;

        }

        .date {
          position: absolute;
          bottom: 46px;
          right: 5px;
        }
      }

      .action {
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        height: @prPiPcActionHeight;
        width: @prPiPcActionWidth;
        padding: @prPiPcContPadding/2 @prPiPcContPadding;
        border-top: @prPiWrapperBorderType @prPiWrapperBorderWidth @prPiWrapperBorderColor;
        font-size: @prPiPcActionFontSize;

        .speaker {
          display: none;
          padding: 0;
          margin: 0;
          height: 100%;
          width: 100%;

          .left {
            display: inline-block;
            width: 30%;
            float: left;

            .glyphicon-pencil {
              font-size: 0.9em;
              color: lighten(black, 30%);

              &:hover {
                color: black;
              }
            }
          }
          .right {
            display: inline-block;
            width: 70%;
            float: left;
            text-align: right;
            color: hsla(207, 55%, 52%, 1);

            .link-both {
              display: inline-block;
              margin: @prPiPcActionBothMargin;
              font-size: 85%;

              span:first-child {
                position: relative;
                margin-right: -9px;
                top: -2px;
              }
              span {
                transition: all 0.3s ease;
                opacity: 0.3;
              }

              span:last-child {
                position: absolute;
                top: 8px;
                right: 21px;
                opacity: 1;
                font-size: 30px;
              }
              &:hover {
                color: @linkColorHover;
                text-decoration: none;
                span {
                  opacity: 1;
                }
                span:last-child {
                  opacity: 0.3;
                }
              }
            }

            .link-extended, .link-beamer {
              a {
                color: @linkColor;
                &:hover {
                  color: @linkColorHover;
                }
              }
            }

            .link-extended {
              .fa-laptop {
                font-size: 1.25em;
              }
              margin-left: 5px;
            }

            &.switcher {
              display: none;
            }

            &.switcher.show-switcher {
              display: flex;
              justify-content: flex-end;
              margin-top: -5px;

              .dropup, .dropdown {
                .dropdown-menu {
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 250px;
                }
              }

            }
          }
        }

        .progress {
          padding: 0;
          margin: 0;
          height: 100%;
          width: 100%;
        }

      }
    }
  }
}

.own-portal-item {
  .presentation-item {
    .wrapper {
      .img {
        cursor: grab;
      }
    }
  }
}

.imaginary-portal-item {
  .imaginary-portal-body {
    .presentation-item {
      .wrapper {
        .img {
          cursor: grab;
        }
      }
    }
    .presentation-item.no-portal {
      .wrapper {
        .presentation-container {
          .img {
            .visibility {
              display: none;
            }
          }
          .right {
            display: none;

            &.switcher {
              display: flex;
              justify-content: flex-end;
              margin-top: -5px;

              .dropup, .dropdown {
                .dropdown-menu {
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 250px;
                }
              }

            }
          }
        }
      }
    }
  }
}

.shared-portal-item {
  .shared-portal-body {
    .presentation-item {
      &.is-shared {
        .wrapper {
          height: 260px;
          .presentation-container {
            .img {
              .clickable;
            }
            .date {
              bottom: 5px;
            }
            .action {
              display: none;
            }
            .foremost {
              display: none;
            }
            .presentation-menu {
              display: none;
            }
          }
        }
      }
    }
  }
}

.portal {
  .wrapper .presentation-container .img {
    .foremost {
      display: none;
    }
    .visibility {
      display: none;
    }
    .presentation-menu {
      display: none
    }
  }
}

.presentation-item.upload-wrapper{
  .wrapper {
    border: 1px dashed black;
    background-color: transparent;
    .presentation-container {
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;

      &:active {
        outline: none;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        .img{
          background-color: #fff;
        }
        background-color: #fff;
      }

      .img {
        font-size: 2em;
        border: none;
        border-bottom: 1px dashed black;
        background-color: transparent;

        .fa{
          margin-left: 0.5em;
        }
      }
      .info {
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
          text-align: center;
        }
      }
    }
  }
}

.own-portal-item {
  .presentation-item.upload-wrapper {
    .wrapper {
      .img {
        cursor: pointer;
      }
    }
  }
}