/**   Portal Item Style
*     Aufbau: Zuertst kommen übergerifende Einstellungen und Variablen dann
*             diverste ausprägungen die die Einstellungen übernehmen.
*
*     Es Gibt 3 Verschiede Typen von Portalen, Own-Portal, Imaginary-Portal und Shared-Portal
*
*     HTML Aufbau: Zuerst kommt das ITEM darin dann Header und Body, Footer bestzt das Portal Item noch nicht.
*                   im Header sitzen dann diverse Wrapper oder Container. Im Body sitzen dann die Präsentations ITMES
*                   die dann im Präsentation ITEM Less aus geprägt werden.
**/

//Variablen mit Kürzel PI für PortalItem
@PI-font-size-drop-down: 1.5em;

// Allgemeine Portal Header einstellungen.
.portal-item-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5em;

  div {
    margin: 0.25em 0.5em;
  }
}

// Allgemeine Drop-area einstellungen;
.portal-item-drop-area {
  z-index: 2;
}

// Allgemeiner Style für Alignment;
.style-flex-end {
  display: flex;
  align-items: flex-end;
  margin: 0;
  flex-grow: 1;
  .title-wrapper{
    .rename-title-form{
      .input-group{
        width: 55%;
        min-width: 320px;
      }
    }
  }
}

//Style von Own-Portal
.own-portal-item {
  display: flex;
  flex-direction: column;
  .drop-area {
    .portal-item-drop-area;
  }

  .own-portal-header {
    .portal-item-header;

    .collapser-wrapper {
      font-size: 2em;
      margin: .25em .25em .2em 0;
    }

    .title-wrapper {
      h2 {
        line-height: 1;
        margin: 0;
      }
    }
    .empty-portal-text {
      font-style: italic;
    }
    .visibility-drop-down {
      .btn {
        display: flex;
        font-size: @PI-font-size-drop-down;
      }
    }
    .settings-drop-down {
      .btn {
        display: flex;
        font-size: @PI-font-size-drop-down;
      }
    }

    .pretty-url-wrapper {
      display: flex;
      align-items: flex-end;
      max-width: 50%;
      flex-grow: 1;
      .edit-url {
        max-width: 100%;
        width: 100%;
        .input-group {
          margin: 0;
          input {
            height: 30px;
          }
          .input-group-btn {
            .btn {
              padding: 4px 10px;
            }
          }
        }
      }
      a.private{
        color: #999;
        &:hover{
          color: #2a6496;
        }
      }
    }

    .btn-group:not(.delete-sure) {
      .fa-fw {
        width: 2em;
      }
      .btn:active, .btn:focus {
        box-shadow: none;
        color: gray;
      }
    }

  }

  .own-portal-body {
    min-height: @prPoPlMinHeight;
    box-sizing: border-box;
    padding-bottom: @prPoPlPaddingBottom;
  }

}

//Style von Imaginary-Portal
.imaginary-portal-item {
  .drop-area {
    .portal-item-drop-area;
  }
  .imaginary-portal-header {
    .portal-item-header;
  }
}

//Style von Shared-Portal
.shared-portal-item {
  .shared-portal-header {
    .portal-item-header;

    .title-wrapper {
      h2 {
        margin: 0;
      }
    }

    .shared-info {
      font-size: 1.2em;
    }

    .pretty-url-wrapper {
      display: flex;
      align-items: center;
      .edit-url {
        width: 50%;
      }
    }

  }
}