@interactiveBorderColor: hsla(hue(@prColor), 50%, 25%, 0.7);
@interactiveBorderColor: hsla(hue(@prColor), 50%, 25%, 0.7);
@interactiveBorderWidth: 1px;
@interactiveProgressColor: hsla(hue(@prColor), 50%, 50%, 0.4);

@interactiveQuestionSize: 1.5em;
@interactiveDescriptionSize: 0.9em;

@interactiveOpenQuestionAnswerColor: dimgray;
@interactiveOpenQuestionAnswerColorOwn: hsla(hue(@prColor), 0%, 70%, 1);
@interactiveOpenQuestionAnswerColorHover: hsla(hue(@prColor), 0%, 0%, 1);
@interactiveOpenQuestionAnswerColorVotedUp: @btn-primary-bg;
@interactiveOpenQuestionAnswerColorVotedDown: @btn-danger-bg;
@interactiveOpenQuestionAnswerVoteUpMargin: 0 10px 0 0;
@interactiveOpenQuestionAnswerVoteDownMargin: 0 0 0 0;

@interactiveMediaHeight: 600px;
@interactiveMediaHeightPlus: 601px;

.ar-wrapper {
  position: relative;

  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  min-height: 0; //FireFox Fix

  @media (max-width: 900px), (min-width: 901px) AND (max-height: @interactiveMediaHeight) {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; //Fix for smooth scrolling on touch devices
  }
  @media (min-width: 901px) AND (min-height: @interactiveMediaHeightPlus) {
    display: flex;
  }

  .ar-header {
    display: flex; // Braucht FF für richtige Größe
    flex-direction: row-reverse;
    .status-block {
      padding-top: 0.2em;
      font-size: 1.5em;
      .running-block {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
        .pos-fix {
          align-self: center;
          font-weight: bold;
          font-size: 1em;
          margin: -0.3em -1.2em 0 0;
        }
        .pr-fa-flipping {
          animation-name: flipping;
          animation-duration: 4s;
          animation-delay: 1.5s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
        }

        @keyframes flipping {
          0% {
            transform: scale(1, 1);
          }
          37% {
            transform: scale(1, 1);
          }
          67% {
            transform: scale(-1, 1);
          }
          70% {
            transform: scale(-1, 1);
          }
          100% {
            transform: scale(1, 1);
          }
        }
      }
      .answered-block {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
        .fa-play-circle-o {
          animation-name: breath;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease;
          animation-direction: alternate;
        }
      }

      @keyframes breath {
        0% {
          opacity: 1
        }
        25% {
          opacity: 1
        }
        100% {
          opacity: 0.45;
        }
      }

    }
    ar-interactive-question {
      flex-grow: 1;
    }
    .question-block {
      .question {
        margin-top: 2px;
        font-size: @interactiveQuestionSize;
        font-weight: bold;
      }
      .description {
        color: gray;
        font-size: @interactiveDescriptionSize;
      }
    }
  }

  .ar-body {
    -ms-flex: 1;
    flex-grow: 1;
    flex-shrink: 100; // braucht Chrome für Richtige Größen
    flex-direction: column;
    overflow-y: auto;

    min-height: 0;
    .quiz-answer, .survey-answer {
      .progress {
        height: 2.4em;
        .progress-bar {
          position: relative;
          &.progress-bar-trans {
            background-color: white;
            color: black;
            border: solid 1px gray;
            border-radius: 4px;
            .progress-bar-text-right {
              justify-content: center;
            }
          }

          .progress-bar-text-left, .progress-bar-text-right {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            height: 100%;
            i {
              margin: 0 0.5em;
            }
          }

          .progress-bar-text-left {
            justify-content: flex-start;
          }

          .progress-bar-text-right {
            justify-content: flex-end;
          }
        }
      }
      .form-group {
        .answer-wrapper {
          position: relative;
          width: ~'calc(100% - 10px)';
          overflow-x: hidden;
          padding: 5px;
          border-style: solid;
          border-width: @interactiveBorderWidth;
          border-color: transparent transparent @interactiveBorderColor @interactiveBorderColor;
          margin: 5px;
          label {
            position: relative;
            z-index: 3;
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            /* &:hover {
               .check-symbol {
                 .pr-stack:not(.cancel-hover) {
                   .pr-square, .pr-circle {
                     font-size: 0.8em;
                   }
                 }
               }
             }*/
            .check-symbol {
              align-self: center;
              .wrong {
                color: @btn-danger-bg;
              }
              .right {
                color: @btn-success-bg;
              }
              .pr-stack {
                width: 1.5em;
                height: 1.5em;
                line-height: 1.5em;
                .pr-square-o {
                  top: 1px;
                  font-size: 1.5em;
                }
                .pr-circle-o {
                  top: 0;
                  font-size: 1.5em;
                }
                .pr-square, .pr-circle {
                  transition: font-size 0.2s ease;
                  font-size: 0;
                }
                &.select {
                  .pr-square {
                    font-size: 0.9em;
                  }
                  .pr-circle {
                    font-size: 0.9em;
                  }
                }
              }
            }
            .answer-text {
              align-self: center;
              margin: 0 10px;
              font-size: 1em;
              flex-grow: 1;
              word-wrap: break-word;
              overflow-y: hidden;
              text-align: left;
            }
            .count {
              white-space: nowrap;
              align-self: center;
              display: inline-flex;
              font-size: 1.3em;
              font-weight: bold;
            }

            &.disabled{
              .check-symbol{
                color: #999;
              }
              .answer-text{
                color: #999;
              }
            }
          }
          progress[value] {
            z-index: 1;

            position: absolute;
            top: 0;
            left: 0;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            background: none;

            height: 100%;
            width: 100%;

            border: none;

            &::-webkit-progress-bar {
              background: none;
            }

            &::-moz-progress-bar {
              background-color: @interactiveProgressColor;
            }

            &::-webkit-progress-value {
              background-color: @interactiveProgressColor
            }
          }
        }
      }
      .btn-group {
        .btn {
          .fa {
            margin-right: 0.5em;
          }
        }
      }
    }

    .ar-interactive-filter {
      display: block;
    }
    .ar-I-typ-output-wrapper {
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      min-height: 0; //FireFox Fix
      .ar-I-o-q-output {
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 0; //FireFox Fix
        @media (max-width: 900px) {
          display: block;
        }
        @media (min-width: 901px) {
          display: flex;
        }
      }

      @media (max-width: 900px) {
        display: block;
      }
      @media (min-width: 901px) {
        display: flex;
      }
    }

    @media (max-width: 900px), (min-width: 901px) AND (max-height: @interactiveMediaHeight) {
      display: block;
    }
    @media (min-width: 901px) AND (min-height: @interactiveMediaHeightPlus) {
      display: flex;
    }
  }

  .ar-footer {
    .show-correct {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .info-text {
        margin-right: 0.5em;
      }
    }
    .button-block {
      .btn-group {
        margin: 5px 0 2px 0;
      }
    }
  }
}

.big-cr-wrap {
  .ar-wrapper {
    .ar-body {
      overflow-x: auto;
      overflow-y: hidden;
      .answer-list {
        flex: 0 1 auto;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: wrap;
        .open-answer {
          width: 330px;
          margin-right: 2px;
        }
      }
    }
  }
}

.survey-answer {
  &.input {
    .form-group .answer-wrapper {
      margin-bottom: 15px;
    }
  }
}

.quiz-answer {
  .progress {
    height: 2em;
    .progress-bar {
      padding-top: 1px;
      font-size: 1.8em;
    }
  }
  .info-correct-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    margin: 15px;
    color: white;
    font-size: 1.3em;
    &.wrong {
      background-color: @btn-danger-bg;
      border-color: @btn-danger-border;
    }
    &.correct {
      background-color: @btn-success-bg;
      border-color: @btn-success-border;
    }
  }
}

.answer-list, .own-answer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  align-content: flex-start;

  .no-user-answers {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .open-answer {
    flex: 0 0 auto;
    transition: all 0.3s ease;

    margin-bottom: 4px;
    padding: 2px 0 2px 5px;
    margin-right: 2px;

    //border-style: solid;
    //border-width: 2px;
    //border-color: white white @prColor @prColor;

    &.greyed-out-answer {
      color: #959595;
      .answer-user {
        i {
          color: black;
        }
      }
      border-color: white;
    }

    &.own-answer {
      border: solid 1px gray;
    }

    &:nth-child(odd) {
      background-color: @prColorOdd;
    }
    &:nth-child(even) {
      background-color: @prColorEven;
    }

    .answer-main {
      .answer-header {
        font-weight: bold;
        .answer-user {
          display: inline-block;
        }
        .answer-date {
          display: inline-block;
          font-size: 0.8em;
          color: @interactiveOpenQuestionAnswerColor;
          margin-left: 0.5em;
        }
        .answer-vote {
          display: inline-block;
          float: right;
          color: @interactiveOpenQuestionAnswerColor;
          margin-right: 5px;

          .answer-vote-up-glyph,
          .answer-vote-down-glyph {
            color: @interactiveOpenQuestionAnswerColorOwn;
          }

          .clickable {

            .answer-vote-up-glyph, .answer-vote-down-glyph {
              color: @interactiveOpenQuestionAnswerColor;
            }

            &.answer-vote-up:hover, &.answer-vote-down:hover {
              color: @interactiveOpenQuestionAnswerColorHover;
              font-weight: bold;
              .answer-vote-up-glyph, .answer-vote-down-glyph {
                color: @interactiveOpenQuestionAnswerColorHover;
                font-weight: bold;
              }
            }
          }

          .answer-vote-up {
            display: inline-block;
            margin: @interactiveOpenQuestionAnswerVoteUpMargin;
            &.voted {
              color: @interactiveOpenQuestionAnswerColorVotedUp;
              .answer-vote-up-glyph {
                color: @interactiveOpenQuestionAnswerColorVotedUp;
              }
              &:hover {
                &.clickable {
                  color: @interactiveOpenQuestionAnswerColorVotedUp;
                  .answer-vote-up-glyph {
                    color: @interactiveOpenQuestionAnswerColorVotedUp;
                  }
                }
              }
            }
          }
          .answer-vote-down {
            display: inline-block;
            margin: @interactiveOpenQuestionAnswerVoteDownMargin;
            &.voted {
              color: @interactiveOpenQuestionAnswerColorVotedDown;
              .answer-vote-down-glyph {
                color: @interactiveOpenQuestionAnswerColorVotedDown;
              }
              &:hover {
                &.clickable {
                  color: @interactiveOpenQuestionAnswerColorVotedDown;
                  .answer-vote-down-glyph {
                    color: @interactiveOpenQuestionAnswerColorVotedDown;
                  }
                }
              }
            }
          }
        }
      }
      .answer-content {
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 2px;
        margin: 3px;
      }
    }
  }
}

.own-answer {
  flex: 0 0 auto;
}

.filter-group {
  &.form-group {
    margin-top: 5px;
    min-height: 35px;
    display: flex;
    justify-content: space-around;

    max-width: 100%;
    overflow-x: hidden;

    .btn-group {
      display: inline-block;
      margin: 0 5px;
      .fa.fa-thumbs-o-down.fa-flip-horizontal{
        margin-left: -0.2em;
      }
      &.toggle-big-btn{
        margin:0;
      }
    }
    .form-control {
      display: inline-block;
      width: 100%;
    }
  }
}

.input-answer {
  padding-bottom: 0.5em;

  .more-answers {
    font-size: .9em;
  }

  .form-group {
    position: relative;
    margin-bottom: 0.2em;
    .max-length {
      position: absolute;
      top: 0.1em;
      right: 0.3em;
      font-size: 0.8em;
    }
  }

  .flex-btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .anonym-text {
      margin-right: 0.2em
    }

    .btn-group .btn .fa {
      margin-right: 0.25em;
    }
  }

  @media (max-width: 900px) {
    .input-flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .form-group {
        flex-grow: 1;
        margin-right: 0.5em;
      }

      .flex-btn-group .btn-group .btn {
        .fa {
          font-size: 1.25em;
          margin: 0.25em;
        }

        .hidden-on-sd {
          display: none;
        }
      }
    }
  }
}

.audience-view {
  .ar-wrapper {
    .ar-body {
      .finished {
        .quiz-answer, .survey-answer {
          .form-group {
            .answer-wrapper {
              label {
                .check-symbol {
                  color: darkgray;
                  .wrong {
                    color: rgba(217, 83, 79, 0.4);;
                  }
                  .right {
                    color: rgba(92, 184, 92, 0.4);
                  }
                  .select {
                    color: black;
                    &.wrong {
                      color: @btn-danger-bg;
                    }
                    &.right {
                      color: @btn-success-bg;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}