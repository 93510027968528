@iCColor: hsla(hue(@prColor), 60%, 53%, 1);
@iCBorderColor: darken(@iCColor, 20%);
@iCBorderWidth: 2px;
.img-folder {
  width: 100%;
  height: auto;
  position: relative;
  .img-size {
    transition: none;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    border: @iCBorderWidth solid @iCBorderColor;
    z-index: 3;
    background-color: white;

    width: 100%;
    height: 400px;
    @media (max-width: 499px) {
      height: 300px;
    }
    &:nth-child(2) {
      transform: rotate(3deg);
      z-index: 2;
      &.animation2{
        transform: rotate(0deg);
        transition: all 1.2s ease;
      }
    }
    &:nth-child(n+3) {
      transform: rotate(-2deg);
      z-index: 1;
      transition: all 0.2s ease;
      &.animation3{
        transition: all 1.2s ease;
        transform: rotate(3deg);
      }
      &.animationAdd{
        height: 0;
        width: 0;
      }
    }
    img {
      width: 100%;
      max-height: 100%;
    }
    &.animation{
      transition: all 1.2s ease;
      transform: rotate(270deg);
      height: 0;
      width: 0;
      margin-top: 200px;
      margin-left: 50%
    }
  }
}