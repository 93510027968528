@DVcontainerSpacing: 5px;
@DVcontainerBackground: hsla(0, 0%, 100%, 1);

@DVcontainerBorderColor: hsla(0, 0%, 70%, 1);
@DVcontainerBorder: solid 1px @DVcontainerBorderColor;

@DVnavbarHeight: 50px;

@DVheaderOutHeight: 165px;
@DVheaderHeight: 50px;
@DVheaderTransition: all 0.2s ease;

@DVheaderMargins: 15px; // 15 px from form-group
@DVheaderMarginOpener: @DVheaderOutHeight - 34px - 40px - 5px - 2px; //119 height - 34 Button - 5 Margin - 2 Border = 78 // @DVheaderOutHeight - 40px = 119

@DVpublicWidth: 500px;

@DVpublicMenuHeight: 30px;
// ToDo abhängig machen von der Font Size
@DVpublicMenuFontSize: 1.5em;
@DVpublicMenuTabs: 2;

@DVpublicCrHeight: 35px;
//ToDo abhängig machen von der Font Size
@DVpublicCrMargin: 5px;
@DVpublicCrHoverColor: #d3d3d3;

.detail {

  .header-wrap {
    transition: @DVheaderTransition;

    position: fixed;
    left: @DVcontainerSpacing;
    right: @DVcontainerSpacing + @DVpublicWidth;
    top: -1px - @DVheaderOutHeight + @DVnavbarHeight + @DVheaderHeight - 6px - @DVheaderMargins; //1px cause of the border, 6px to finde out.

    height: @DVheaderOutHeight + @DVheaderMargins;

    background-color: @DVcontainerBackground;
    border: @DVcontainerBorder;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    line-height: 1;

    .options {
      padding: 0 10px;
      margin-top: 5px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      position: relative;
      z-index: 0;

      .inputs {
        display: flex;
        width: 80%;
        .header-form {
          .form-group {
            display: inline-flex;
            flex-direction: column;
            width: 45%;

            &:first-child {
              margin-right: 6%;
            }

            &:nth-child(3) {
              margin-right: 6%;
            }

            .no-margin {
              button:disabled {
                border-top: solid 1px gray;
                border-bottom: solid 1px gray;
              }
            }
          }
        }
        .form-group:last-child {
          margin-bottom: 0;
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        transition: opacity 0.2s ease;
        transition-delay: 0.2s;
        opacity: 0;
        align-self: flex-end;
        flex-grow: 1;
        .btn {
          margin-bottom: 0.5em;
          max-width: 150px;
          min-width: 120px;
          align-self: flex-end;
        }
        .btn-toggle {
          margin-bottom: 17px;
        }
      }
    }
    .title {
      transition: all 0.1s ease;

      position: relative;
      bottom: 0;

      z-index: 1;

      padding: 0 10px;
      margin-top: -1px;

      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .name {
        transition: opacity 0.2s ease;
        font-size: 22px;
      }
      .btn-group {
        transition: margin 0.2s ease;
      }

    }

    &.out {
      top: @DVnavbarHeight - 1;
      z-index: 1;
      height: @DVheaderOutHeight - 40px + @DVheaderMargins;
      .options {
        z-index: 1;
        .buttons {
          transition-duration: 0s;
          transition-delay: 0s;
          opacity: 1;
        }
      }
      .title {
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: @DVheaderMarginOpener + @DVheaderMargins;
        z-index: 0;
      }
      .name {
        opacity: 0;
      }
    }

  }

  .slide-wrap {
    position: fixed;
    left: @DVcontainerSpacing;
    bottom: @DVcontainerSpacing;
    top: @DVnavbarHeight + @DVcontainerSpacing + @DVheaderHeight;
    right: @DVpublicWidth + @DVcontainerSpacing;
  }

  .public-wrap {

    position: fixed;

    top: @DVcontainerSpacing + @DVnavbarHeight;
    bottom: @DVcontainerSpacing;
    right: -1px;

    width: @DVpublicWidth + 1px;

    background: @DVcontainerBackground;
    border: @DVcontainerBorder;

    .menu {
      width: 100%;
      height: @DVpublicMenuHeight;

      .menu-btn {
        display: inline-block;
        width: 100% / @DVpublicMenuTabs;
        height: 100%;
        text-align: center;

        font-size: @DVpublicMenuFontSize;
        font-weight: bold;

        border-left: @DVcontainerBorder;
        border-bottom: @DVcontainerBorder;

        &:hover {
          text-decoration: underline;
        }

        &:first-child {
          border-left: none;
        }

        &.active {
          border-bottom: none;
        }
      }
    }

    .content {
      height: ~"calc(100% -" @DVpublicMenuHeight ~")";
      overflow: auto;
      padding: 5px 10px 5px 5px;

      .front {
        display: flex;
        flex-direction: column;
      }

      .cr {

        height: 100%;

        h3 {
          margin-top: 5px;
        }

        .btn-group {
          margin-bottom: 10px;
        }

        .cr-item {
          display: flex;
          align-items: center;
          width: 100%;

          font-size: 16px;
          min-height: @DVpublicCrHeight;
          padding: @DVpublicCrMargin @DVpublicCrMargin @DVpublicCrMargin 0;

          &.clickable:hover {
            background-color: @DVpublicCrHoverColor;
          }

          .row1 {
            display: inline-flex;
            justify-content: center;
            width: 30px;
            i {
              font-size: 16px;
            }
          }
          .row2 {
            display: inline-block;
            width: ~"calc(100% - 165px)";
          }
          .row3 {
            display: inline-flex;
            justify-content: flex-end;
            padding-left: 5px;
            width: 135px;
            font-size: 14px;
            .btn-group {
              margin-bottom: 0;
            }
          }

        }

        .options {
          width: 100%;
          display: flex;
          justify-content: space-between;
          min-height: 40px;
          padding-right: 5px;
          .btn-group {
            margin-bottom: 0;
          }
          .dropdown-menu li a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
            i {
              width: 25px;
              text-align: center;
            }
          }
        }
        .list {
          flex-grow: 1;
          overflow: auto;
        }

      }

      .back {

        .input-block {
          .option-element {
            margin-bottom: 10px;
            margin-left: 5px;

            &.number-input {
              .input-group {
                width: 50%;

                .info {
                  background-color: @btn-primary-bg;
                  color: white;
                }
              }
            }

          }
          .choice-switch {
            margin-bottom: 5px;
          }
          .error {
            border-color: red;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px red;
          }
          .option-start {
            margin-top: 20px;
          }
          .answers-block {
            .input-group {
              margin-top: 3px;
            }
          }
        }
        .footer {
          margin-top: 10px;
        }
      }
    }
  }

}