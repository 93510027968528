.upsert{
  width: 100%;
  .upsert-header{}
  .upsert-body{
    .choice-switch{
      margin-bottom: 0.2em;
    }
    .answers-block{
      .input-group{
        margin-bottom: 0.2em;
      }
    }
    .number-input{
      .input-group{
        width: 50%;
      }
    }
    .option-element {
      margin-bottom: 0.8em;
    }
  }
  .upsert-footer {
    .btn-group {
      margin: 1em 0;
    }
  }
}


.extended-view{
  .upsert{
    .upsert-header{
      h3{
        margin-top: 0.25em;
      }
    }
    .upsert-footer{
      .btn-group{
        margin-bottom: 0.5em;
      }
    }
  }
}

