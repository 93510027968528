//
//
// Modal Login
//-------------------------------------

#loginModal {
  .modal-dialog {
    .modal-content {
      padding-bottom: 10px;
      .modal-header {
        ul {
          margin-top: 16px;
          margin-bottom: -16px;
          position: relative;
          z-index: 100;

          .btn-tu-nav-wrapper {
            padding: 0;
            height: 35px;
          }

          .btn-tu-nav {
            margin: 2px 0 0 0;
            width: 27px;
            height: 27px;
          }
        }
      }
    }

    .modal-body {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      .forgot-pw-line {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        a {
          font-size: 0.9em;
          margin: 0.25em;
        }

      }
    }

    .list-background {
      .active {
        a {
          background-color: white;
          border-bottom-color: @prColorBackground;
        }
      }
      a {
        white-space: nowrap;
      }
    }

    .SSOLogin {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-tu {
        margin: 5px;
        width: 120px;
        height: 120px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 15px;
      }
    }

    .presentr-login {
      padding: 5px;
    }

    .login-info {
      font-weight: bold;
      &.flex {
        display: flex;
        justify-content: center;
      }

      h2 {
        font-weight: bold;
      }

    }

    .login-row {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      a {
        margin-right: 20px;
      }
      .btn {
        margin-left: 5px;
      }
    }

    .registerSpeaker {
      .agb-form-group{
        .control-label{
          padding-top: 0;
        }
        .col-lg-60{
          display: flex;
          flex: 1 1 auto;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
        }
        .agb-space{
          margin: 0 0.25em;
        }
        .help-block{
          padding-top: 0.5em;
        }
        /* Customize the label (the container) */
        .container {
          display: inline-block;
          width: auto;
          position: relative;
          padding-left: 35px;
          margin:  auto 0.25em 0 0;
          cursor: pointer;
          font-size: 22px;
          height: 25px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          border-radius: @border-radius-base;
          border: solid 1px @input-group-addon-border-color;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #2196F3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 7px;
          top: 4px;
          width: 5px;
          height: 11px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          box-sizing: content-box;
        }
      }
    }
  }
}