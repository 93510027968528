// ToDo selbst generierte Mixins

.padding(@scale: 1) {
  padding: @StandardPadding * @scale;
}

.margin(@scale: 1) {
  margin: @StandardMargin * @scale;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.border-radius-bottom-only {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}