//
// Modal Export
//-------------------------------------

#exportModal {
  .modal-dialog {
    .modal-content {
      .option-row{
        display: flex;
        margin: 10px 5px 0 5px;
        @media(max-width: 400px){
          align-items: flex-start;
          flex-direction: column;
        }
        @media(min-width: 401px){
          align-items: center;
          justify-content: space-between;
        }
        div{
          min-width: 10px;
          align-self: center;
        }
      }
    }
  }
}