.account-page {

  //Needed for old header
  margin-top: -20px;
  //Needed for old header

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;

  .warning-mail {
    position: absolute;
    top: 25.5em;

    z-index: 999;

    display: flex;
    width: 100%;
    justify-content: center;

    &.padding{
      padding-bottom: 1em;
    }

    .mail-warning {
      width: 50%;
      background-color: hsla(hue(@alert-danger-bg), 41%, 91%,0.75);
      color: @alert-danger-text;
      font-size: 1.1em;
      padding: 2em;
      margin-top: 1em;
      border-radius: @border-radius-base;
      align-items: center;
      justify-content: center;
      border: solid 1px;

      span {
        font-size: 1.1em;
        margin-bottom: 2em;
      }
      .btn {
        width: 250px;
        padding: 2px;
        font-size: 1em;
      }
    }
  }
  .abo-section {
    display: flex;
    width: 100%;
    justify-content: center;
    &.disabled {
      pointer-events: none;
      .abos {
        background-color: hsla(0, 0, 92%, 1);
        color: hsla(0, 0, 79%, 1);
        padding-top: 0;
        .btn {
          opacity: 0.5;
        }

        a {
          color: hsla(0, 0, 79%, 1);
        }
      }
    }
    .abos {
      display: flex;
      flex: 1 1 auto;
      padding-top: 3.5em;
      .basis, .trail {
        border-right: solid 1px #999;

        .btn.btn-success, .btn.btn-default {
          width: 250px;
          font-size: 1.1em;
          align-self: center;
        }

        p {
          &.text {
            min-height: 80px;
          }
        }

        .list-header {
          font-weight: bold;
          text-align: left;
        }
        .list {
          min-height: 255px;
        }
        ul {
          align-self: flex-start;
          text-align: left;
          li {
            margin-bottom: 0.5em;
          }
        }

        .info-link {
          min-height: 80px;
          a {
            margin-left: 0.25em;
          }
        }

        &.gray-out {
          background-color: hsla(0, 0, 92%, 1);
          color: hsla(0, 0, 79%, 1);

          .btn.btn-default {
            background: none;
          }
        }
      }
      .basis {
        border-top-left-radius: @border-radius-base;
        border-bottom-left-radius: @border-radius-base;

        .btn.btn-default {
          margin-bottom: 5.1em;
        }
      }
      .trail {
        .btn {
          transition: all 0.5s ease;
          width: 100%;
          font-size: 1.5em;
          margin-bottom: 0;
          &.btn-default {
            margin-bottom: 0;
          }
          &.btn-success {
            margin-bottom: 0;
          }
          &.abo-margin-bottom {
            margin-bottom: 5.1em;
            pointer-events: none;
          }
        }

        .info-text-trail {
          margin-bottom: 0;
          margin-top: 0.25em;
        }
        .btn-group-yes-no {
          width: 100%;
          .btn {
            width: 50%;
            font-size: 1.1em;
            margin-bottom: 0;
          }
        }
        .trail-question {
          transition: all 0.5s ease;
          pointer-events: none;
          opacity: 0;

          &.show-question {
            pointer-events: auto;
            opacity: 1;
          }
        }

      }
      .pro {
        .option {
          .pay-options {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            align-items: center;

            .pay-option {
              width: 250px;

              display: flex;
              flex: 1 1 auto;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              border: solid 1px #999;
              border-radius: @border-radius-base;
              margin: 0.25em;

              cursor: pointer;

              padding: 0.9em 0.5em 0.5em 3.75em;
              position: relative;

              .fa {
                position: absolute;
                top: 0.2em;
                left: 0.3em;

                font-size: 2.3em;

                z-index: 101;

                color: #999;

                display: none;

                &.is-unchecked {
                  display: block;
                }

                &.is-checked {
                  display: block;
                }

              }

              .head-line {
                font-size: 1.25em;
                font-weight: bold;
                line-height: 1;
              }
              .price-line {
                font-size: 1.7em;
                line-height: 1;
                margin: 0.5em 0;

                .price-info {
                  font-size: 1.4rem;
                }
              }
              .info-line {
                font-size: 1em;
                line-height: 1;
              }
              &.active {
                .fa {
                  color: black;
                }
              }
              &:hover {
                .fa {
                  &.fa-circle-o {
                    display: none;
                  }

                  &.fa-check-circle-o {
                    display: block;
                  }
                }
              }
            }
          }
        }
        .pay-header, .option-header {
          margin-top: 0.5em;
          font-size: 1em;
          font-weight: bold;
          text-align: left;
        }
        .option-header{
          margin-bottom: 0.25em;
        }
        .pay-header{
          margin-bottom: 0.5em;
        }
        .placeholder {
          min-height: 320px;
        }
        .placeholder-pro {
          min-height: 250px;
          display: flex;
          align-items: flex-end;
          padding-bottom: 1.5em;
        }
        .no-pointer-event {
          pointer-events: none;
        }
        .info-pro-duration {
          font-weight: bold;
          font-size: 1.25em;

          margin: 0.25em 0;
        }
        .info-pro-more {
          font-weight: bold;
          font-size: 1em;

          margin: 0.25em 0;
        }
        .btn-group {
          margin-bottom: 3.1em;
        }
        .info-btn {
          margin-bottom: 5.55em;
          .btn {
            width: 250px;
            font-size: 1.1em;
            &.btn-info {
              margin-bottom: 1em;
              background-color: #009cde;
            }
          }
        }
        .info-link {
          a {
            margin-left: 0.25em;
          }
        }
      }
      .basis, .trail, .pro {
        padding: 2.5em 2.5em 0 2.5em;

        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: space-between;

        width: 33.33333%;

        h3 {
          margin-top: 0;
        }
      }
    }
  }
  .user-section {
    display: flex;
    width: 100%;
    justify-content: center;
    h2 {
      margin-bottom: 1em;
      margin-top: 0;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
    }
    .form-group {
      display: flex;
      flex-wrap: wrap;
      min-width: 50%;
      position: relative;
      align-items: center;

      label {
        display: inline-flex;
        width: 50%;
        justify-content: flex-end;
        padding-right: 1em;
      }
      input {
        display: inline-flex;
        width: 50%;
      }

      .change-mail-link{
        position: absolute;
        left: 100%;
        font-size: 0.8em;
        align-self: flex-end;
        margin-left: 1em;
        width: 50%;
        display: flex;

      }
    }
    .button-row {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      .btn-warning{
        margin-right: 1em;
      }
    }
    .no-margin {
      height: 0;
      margin-bottom: 0;
    }
    .form-horizontal {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .time-line-section {
    h2 {
      margin: 0 0 0.5em 0;
      text-align: left;
    }

    display: flex;
    width: 100%;
    justify-content: center;
    //background-color: #3f80cf;

    .table-wrapper {
      width: 100%;
      padding: 1em;
      border-radius: 1em;

      display: flex;
      justify-content: center;
    }
    table {
      width: 85%;
      .ta-info {
        padding-left: 1em;
      }
      .ta-date {
        text-align: left;
      }
      .ta-price {
        text-align: left;
        padding-right: 1.5em;
      }
      tr.bg-white {
        th.ta-price {
          padding-right: 1em;
        }
        th.ta-info {
          padding-left: 0.5em;
        }
      }
      tr {
        td {
          text-align: left;
          padding: 0.3em 0.5em;
          .currency {
            margin-right: 0.25em;
            .amount{
              margin-right: 0.15em;
              display: inline-block;
              text-align: right;
              width: 20px;
            }
          }
        }
        .empty-table {
          font-size: 1.25em;
          font-weight: bold;
          width: 100%;
          text-align: center;
        }
        &:first-child {
          border-bottom: solid 1px black;
          font-size: 1.3em;
          &.bg-white {
            background-color: white;
          }
          th {
            padding: 0.25em;
            padding-bottom: 0.25em;
          }
        }
        &:nth-child(odd) {
          background-color: hsla(0, 0, 91%, 1);
        }
        &:nth-child(even) {
          background-color: white;
        }
        &:nth-child(2) {
          td {
            padding-top: 0.5em;
          }
        }
      }
    }
  }
  hr {
    width: 100%;
    max-width: 1200px;
    background-color: #999;
  }
  h2 {
    text-align: left;
  }
  .max-size {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    max-width: 1140px;
    text-align: center;
  }
  .padding {
    padding: 3.5em;
    &:first-child {
      padding-top: 0;
    }
  }
  .own-footer {
    display: block;
    width: 100%;

    .max-size {
      flex-direction: row;
      text-align: left;
    }
  }
}