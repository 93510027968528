.instance{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 55px;
  .instance-top{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .btn-tu{
      width: 150px;
      height: 150px;
    }
    .logo{
      width: 150px;
      height: 150px;
      background-color: @prColor;
      padding: 10px;
      border-radius:10px;
    }
  }
  .instance-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-search{
    width:70%
  }
}