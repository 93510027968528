.slide-container-tag {
  .slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    //border: solid 1px @prColor;

    overflow: hidden;
    &:fullscreen {
      background-color: black;
    }

    &:-webkit-full-screen {
      background-color: black;
    }
  }
}