/**
*   Presentation-Item-Small have three Container: Img-Wrap, Title-Wrap and Mark-wrap but the Mark-Wrap ist not needed
*   It will always take 50% from the Parent Container and has 5px margin right and bottom.
*   the Height is fixed on 200px (img 150px and title 50px)
*   Only used on Admin page
*
*   Need some Refactor for Sizing if needed Some Where Else.
**/

@PIS-border-color: hsla(hue(@prColor), 7%, 84%, 1);
@PIS-background-color: white;

.presentation-small{
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: ~'calc(50% - 5px)';
  margin-right: 5px;
  margin-bottom: 5px;
  border: solid 1px @PIS-border-color;
  background-color: @PIS-background-color;

  .img-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    border-bottom: solid 1px @PIS-border-color;

    img{
      max-width: 100%;
      max-height: 100%;
    }

    .visibility {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      color: rgba(0, 0, 0, 1);
      font-size: 25px;
      border-bottom-right-radius: 4px;
      padding: 5px;
    }
  }
  .title-wrap{
    padding:5px;
    height: 50px;
    overflow: hidden;

  }
  .mark-wrap{
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }

  &.marked{
    .mark-wrap{
      display: flex;
      color: @btn-danger-bg;
      font-size: 5em;
    }
  }

}