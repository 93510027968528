.franz {
  position: relative;
  font-size: inherit;

  .top {
    position: fixed;
    bottom: 24px;
    right: 24px;
    background-color: white;
    border-radius: 0.25em;
    border: solid 2px black;
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  h2 {
    padding-top: 0.25em;
    padding-bottom: 0.5em;
    width: 100%;
    text-align: center;
    font-size: 2.5em;
  }

  .black {
    width: 100%;
    background-color: black;
    color: white;
  }

  .blue {
    width: 100%;
    background-color: #3f80cf;
    color: white;
  }

  .white {
    width: 100%;
    background-color: white;
    color: black;

    .line {
      width: 100%;
      height: 1px;
      background-color: #999;
    }
  }

  .media {

    @keyframes picAnimation1Width {
      from {
        transform: translate(0,0);
      }
      to {
        transform: translate(-20%, 30%) scale(1.75);
      }
    }
    @keyframes picAnimation2Width {
      from {
        transform: translate(0,0);
      }
      to {
        transform: translate(0, 30%) scale(1.75)
      }
    }

    @keyframes picAnimation1Height {
      from {
        transform: translate(0,0);
      }
      to {
        transform: translate(-50%, 30%) scale(1.75);
      }
    }
    @keyframes picAnimation2Height {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-30%, 30%) scale(1.75);
      }
    }

    @keyframes picAnimation1Height2 {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-80%, 30%) scale(1.75);
      }
    }
    @keyframes picAnimation2Height2 {
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(-35%, 30%) scale(1.75);
      }
    }

    @keyframes showHide {
      0% {
        opacity: 1;
      }
      99% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes showHideOne {
      0% {
        opacity: 1;
      }
      99% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes onlyShow {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    margin-top: -70px;
    padding-top: 70px;

    @opAnimationDuration: 0.4s;
    @opAnimationDelay: 6.9s;
    @opAnimationStartDiv: 7;
    @opAnimationLastDiv: 12;

    .animation-loop(@startCount,@endCount) when (@startCount <= @endCount) {
      .animation-loop((@startCount + 1), @endCount);
      .a@{startCount} {
        opacity: 0;
        animation-name: showHide;
        .duration(@opAnimationDuration, @startCount - @opAnimationStartDiv);
      }
    }
    .duration(@dur, @count) {
      animation-duration: @dur;
      animation-delay: @opAnimationDelay + @opAnimationDuration * @count;
    }

    h2 {
      position: absolute;
      padding-top: 0.25em;
      margin-top: 1em;
      top: 100px;
      left: 0;
      display: flex;
      justify-content: center;
      font-size: 2.85em;

      .a1 {
        opacity: 0;
        animation-name: showHideOne;
        animation-duration: 2s;
      }

      .a2 {
        opacity: 0;
        animation-name: showHide;
        animation-duration: 1.5s;
        animation-delay: 2s;
      }

      .a3 {
        opacity: 0;
        animation-name: showHide;
        animation-duration: 1.2s;
        animation-delay: 3.5s;
      }

      .a4 {
        opacity: 0;
        animation-name: showHide;
        animation-duration: 1s;
        animation-delay: 4.7s;
      }

      .a5 {
        opacity: 0;
        animation-name: showHide;
        animation-duration: 0.7s;
        animation-delay: 5.7s;
      }

      .a6 {
        opacity: 0;
        animation-name: showHide;
        animation-duration: 0.5s;
        animation-delay: 6.4s;
      }

      .finish {
        opacity: 0;
        animation-name: onlyShow;
        animation-duration: 2s;
        animation-delay: @opAnimationDelay + @opAnimationDuration * (@opAnimationLastDiv - @opAnimationStartDiv);
        animation-fill-mode: forwards;
      }

      .animation-loop(@opAnimationStartDiv, @opAnimationLastDiv);

      .place-words {
        position: relative;
        display: inline-block;

        span {
          white-space: nowrap;
        }
      }
    }

    position: relative;

    .picture-wrapper {
      position: absolute;
      width: 100%;

      top: 0;
      left: 0;
      bottom: 0;

      .picture1 {
        position: absolute;
        bottom: 0;
        left: 0;
        animation-duration: 19s;
        animation-delay: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
      }

      .picture2 {
        position: absolute;
        bottom: 0;
        left: 0;
        animation-duration: 19s;
        animation-delay: 1s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
      }
    }

    @keyframes blackout {
      0% {
        background-color: hsla(0, 100%, 0%, 0.5);
      }

      90% {
        background-color: hsla(0, 100%, 0%, 0.5);
      }
      92% {
        background-color: hsla(0, 100%, 0%, 1);
      }
      95% {
        background-color: hsla(0, 100%, 0%, 1);
      }
      100% {
        background-color: hsla(0, 100%, 0%, 0.5);
      }
    }

    .black-out {
      position: absolute;
      top: 0;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: hsla(0, 100%, 0%, 0.5);

      animation-name: blackout;
      animation-duration: 20s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &.width {
      .picture-wrapper {
        .picture1{
          animation-name: picAnimation1Width ;
          max-width: 100%;
        }
        .picture2{
          animation-name: picAnimation2Width ;
          max-width: 100%;
        }
      }
    }

    &.height {
      .picture-wrapper {
        .picture1 {
          animation-name: picAnimation1Height;
          max-height: 100%;
        }

        .picture2 {
          animation-name: picAnimation2Height;
          max-height: 100%;
        }
      }
    }

    &.height2 {
      .picture-wrapper {
        .picture1 {
          animation-name: picAnimation1Height2;
          max-height: 100%;
        }

        .picture2 {
          animation-name: picAnimation2Height2;
          max-height: 100%;
        }
      }
    }

  }

  .full-size {
    height: 75vh;
    padding-bottom: 0;
  }

  .last-info {
    &.padding {
      padding-top: 2em;
      padding-bottom: 2.25em;
    }

    a {
      color: white;
      font-size: 1.3em;
      border: solid 1px white;
      border-radius: 0.5em;
      padding: 0.7em;
      background: none;
      white-space: pre-wrap;
      max-width: 250px;

    }

    i {
      font-size: 3em;
      padding: 0.5em 0;
    }

    .max-size {
      text-align: center;
      align-items: center;

      .btn:hover {
        background-color: rgba(255, 255, 255, .2);
        color: #fff;
        text-decoration: underline;
      }
    }

    p {
      font-size: 1.25em;
    }

    h3 {
      font-size: 1.6em;
      margin-bottom: 0.5em;
    }
  }

  .storys {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .pres-img-landing {
      width: 100%;
      max-height: 100%;
    }

    .presentation-wrapper {
      position: relative;
      width: 800px;
      height: 542px;
      border: solid 1px hsla(0, 0%, 93%, 1);
      background-color: white;
      margin-right: 5%;
      overflow: hidden;
      padding-bottom: 42px;

      .slider-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: hsla(hue(@prColor), 30%, 95%, 0.75);

        .landing-slider {
          margin-top: -1px;
        }

        .bottom-lane {
          width: 100%;
          display: flex;
          flex: 0 1 auto;
          justify-content: space-between;

          .postition {
            display: flex;
            flex: 0 1 auto;
            justify-content: center;
            align-items: center;

            font-weight: bold;
            color: hsla(hue(@prColor), 60%, 30%, 1);
            font-size: 1.2em;
            padding-left: 0.75em;
          }

          .btn-group {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn {
              padding: 0.5em;
              background: none;
              color: hsla(hue(@prColor), 60%, 30%, 1);
            }
          }
        }

        @sliderStartHeight: 6px;
        @sliderStartThumbWidth: 20px;

        @sliderTransitionTime: 0.3s;
        @sliderTransitionEffect: ease;
        @sliderTransition: all @sliderTransitionTime @sliderTransitionEffect;

        .pr-thumb {
          width: @sliderStartThumbWidth;
          height: @sliderStartHeight+10px;
          cursor: pointer;
          border-radius: 0;
          border: none;
          background: hsla(hue(@prColor), 50%, 30%, 1);
        }

        .pr-track {
          transition: @sliderTransition;
          margin-top: 1px;
          height: @sliderStartHeight;
          background: hsla(hue(@prColor), 50%, 50%, 0.9);

          outline: none;
        }

        input[type=range] {
          -webkit-appearance: none;

          &:focus {
            outline: none;
          }

          &::-webkit-slider-container {
            .pr-track;
          }

          &::-webkit-slider-thumb {
            .pr-thumb;
            -webkit-appearance: none;
          }

          &::-ms-thumb {
            .pr-thumb;
          }

          &&::-ms-track {
            .pr-track;
            background: transparent;
            border: none;
            color: transparent;

          }

          &::-ms-fill-lower {
            background: darken(hsla(hue(@prColor), 50%, 50%, 0.9), 5%);
            border: none;
          }

          &::-ms-fill-upper {
            background: hsla(hue(@prColor), 50%, 50%, 0.9);
            border: none;
          }

          &::-moz-range-thumb {
            .pr-thumb;
          }

          &::-moz-range-track {
            .pr-track;
            border: none;
          }

          &::-ms-tooltip {
            display: none;
          }
        }
      }

      .slide-wrapper {
        transition: left ease 0.5s;

        width: 800px;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;

        font-size: 8em;

        position: absolute;
        top: 0;
        left: 100%;

        &.active {
          left: 0;
        }
      }
    }

    .presentation-nav {
      width: 20%;
      height: 500px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;

      .presentation-wrapper-nav {
        height: 160px;
        width: 256px;
        background-color: white;
        border: solid 1px hsla(0, 0%, 93%, 1);
        font-size: 3em;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          border-color: black;
        }
      }
    }
  }

  .features {
    .feature {
      border: black 1px solid;
      background-color: white;
      color: black;

      .icon {
        width: 100%;
        text-align: center;
        padding: 2rem;

        img {
          max-height: ~'calc(160px - 4rem)';
        }
      }

      .info {
        padding: 0.4em;
        border-top: solid black 1px;
        width: 100%;
        text-align: center;

        p {
          padding-left: 0.1em;
        }
      }

      .all-in-one {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 2rem;
        height: 160px;

        .glyphicon {
          font-size: 6rem;
        }

        .fa {
          font-size: 3.5rem;
          margin: 1.3rem;
        }

        span {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 65%;
          }
        }
      }

      .export-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;

        .fa-file-pdf-o {
          font-size: 8.5rem;
          margin-top: -3rem;
        }

        .fa-download {
          font-size: 4.5rem;
          position: absolute;
          top: 100px;
          left: 160px;
        }

      }

      .globe {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;

        .fa-globe {
          font-size: 13rem;
        }
      }
    }

    .max-width {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .more-features {
        margin-top: 0.5em;
        padding: 0.5em;
        font-size: 1.25em;

        color: white;
        text-decoration: none;
        text-align: right;

        &:hover {
          color: lightgrey;
        }
      }
    }
  }

  .best-practice {
    .practice {
      h3 {
        i {
          margin-right: 0.5em
        }
      }

      .content {
        margin: 1em 0 1.5em 2.5em;
        display: flex;
        align-items: flex-start;

        .img {
          padding: 0.5em;
          border: solid 1px #ddd;
          border-radius: 5px;
          height: auto;

          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 100%;
            width: 100%;
          }
        }

        .block {
          .info-header {
            font-weight: bold;

            &:not(:first-child) {
              margin-top: 0.5em;
            }
          }

          margin-left: 2.5em;
          max-width: ~'calc(55% - 2.5em)';
        }
      }
    }
  }

  .btn-wrapper {
    .btn-group {
      .btn {
        box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.75);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .btn-wrapper {
      width: 100%;
      background-color: transparent;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .max-size {
        justify-content: center;
        align-items: center;

        .btn-group {
          margin-top: -0.25em;

          .btn {
            letter-spacing: 0.05em;
            font-size: 1.7em;
            padding: 0.2em 1.2em;
          }
        }
      }
    }

    .how-to {
      .part-one, .part-two, .part-three {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 30%;

        i {
          text-align: center;
          font-size: 10em
        }

        h3 {
          font-size: 1.5em;
          text-align: center;
        }

        p {
          text-align: center;
          width: 100%;
        }
      }

      .part-one {
        .fa-container {
          position: relative;

          .fa-transparent {
            color: transparent;
          }

          .fa-size {
            &.fa-upload {
              position: absolute;
              top: 0;
              font-size: 6em;
              left: 0;
              right: 0;
            }

            &.fa-file-pdf-o {
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: 4em;
            }

            &.fa-file-powerpoint-o {
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 4em;
            }
          }
        }
      }

      .part-two {
        .fa-container {
          position: relative;
          display: flex;
          align-items: flex-end;
          min-height: 140px;

          .fa-size {
            margin: 0.5rem;

            &.fa-mobile {
              font-size: 5.5em;
              margin-bottom: 1.5rem;
            }

            &.fa-laptop {
              font-size: 8em
            }

            &.fa-tablet {
              font-size: 5.5em;
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      .width30 {
        width: 30%;
      }

      .arrow-two-to-three, .arrow-one-to-two {
        img {
          width: 100%;
          height: 100px;
        }

        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow-two-to-three {
        img {
          transform: scaleY(-1);
        }
      }

      .width30 {
        display: none;
      }

      .arrow-two-to-three, .arrow-one-to-two {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .how-to {
      .max-size {
        flex-direction: column;
        align-items: center;

        .width30 {
          display: none;
        }

        .arrow-two-to-three, .arrow-one-to-two {
          display: none;
        }
      }

      .part-one, .part-two, .part-three {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
        margin-top: 2em;

        i {
          text-align: center;
          font-size: 6.5em
        }

        h3 {
          font-size: 1.5em;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }

      .part-one {
        .fa-container {
          position: relative;

          .fa-transparent {
            color: transparent;
          }

          .fa-size {
            &.fa-upload {
              position: absolute;
              top: 0;
              font-size: 3.5em;
              left: 0;
              right: 0;
            }

            &.fa-file-pdf-o {
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: 3em;
            }

            &.fa-file-powerpoint-o {
              position: absolute;
              bottom: 0;
              left: 0;
              font-size: 3em;
            }
          }
        }
      }

      .width30 {
        display: none;
      }

      .arrow-two-to-three, .arrow-one-to-two {
        display: none;
      }
    }

    .btn-wrapper {
      width: 100%;
      background-color: transparent;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .max-size {
        justify-content: center;
        align-items: center;

        .btn-group {
          margin-top: -0.25em;

          .btn {
            letter-spacing: 0.05em;
            font-size: 1.2em;
            padding: 0.5em 1.2em;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1140px) {
    .features {
      .feature {
        width: 23.5%;

        .icon {
          font-size: 8em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 200px;
        }
      }

      .max-width {
        .more-features {
          width: 20%;
        }
      }
    }

    .best-practice {
      .practice {
        .content {
          .img {
            width: 45%;
            //height: 250px;
          }
        }
      }
    }

    .media {
      .place-words {
        width: 115px;

        span {
          left: 0.25em;
          top: 0;
          position: absolute;
        }
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1139px) {
    .storys {
      .max-size {
        justify-content: center;
      }

      .presentation-wrapper {
        width: 644px;
        height: 438px;

        .slide-wrapper {
          width: 644px;
          height: 402px;
        }
      }

      .presentation-nav {
        height: 402px;

        .presentation-wrapper-nav {
          height: 119px;
          width: 190px;
        }
      }
    }

    .features {
      .feature {

        width: 265px;
        margin: 2.5em;

        .icon {
          font-size: 8em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 190px;
        }
      }

      .max-width {
        .more-features {
          width: 25%;
        }
      }

      .max-size {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .best-practice {
      .practice {
        .content {
          .img {
            width: 45%;
            //height: 250px;
          }
        }
      }
    }

    .media {
      h2 {
        font-size: 2.5em;
        margin-top: 0.5em;
      }

      .place-words {
        width: 115px;

        span {
          left: 0.25em;
          top: 0;
          position: absolute;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .storys {
      .max-size {
        justify-content: center;
      }

      .presentation-wrapper {
        width: 544px;
        height: 376px;

        .slide-wrapper {
          width: 544px;
          height: 340px;
        }
      }

      .presentation-nav {
        height: 340px;

        .presentation-wrapper-nav {
          height: 100px;
          width: 170px;
        }
      }
    }

    .features {
      .feature {
        width: 265px;
        margin: 2.5em;

        .icon {
          font-size: 8em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 190px;
        }
      }

      .max-width {
        .more-features {
          width: 20%;
        }
      }

      .max-size {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .best-practice {
      .practice {
        .content {
          margin: 2em 1em 2em 1em;
          flex-direction: column;
          align-items: center;

          .img {
            width: 80%;
            //height: 350px;
          }

          .block {
            margin-top: 2.5em;
            max-width: 80%;
            margin-left: 0;

            .info-header {
              width: 100%;
            }
          }
        }
      }
    }

    .media {
      h2 {
        font-size: 2.5em;
        margin-top: 0.5em;
        flex-direction: column;
        align-items: center;
      }

      .place-words {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          position: absolute;
          top: auto;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @media screen and (min-width: 480px) and (max-width: 767px) {
    .storys {
      .presentation-wrapper {
        display: none;
      }

      .presentation-nav {
        width: 100%;
        height: auto;

        .presentation-wrapper-nav {
          height: auto;
          margin-bottom: 1em;
          width: 85%;

          &.active {
            border-color: hsla(0, 0%, 93%, 1);
          }
        }
      }
    }

    .features {
      .feature {
        width: 265px;
        margin: 2.5em;

        .icon {
          font-size: 8em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 190px;
        }
      }

      .max-width {
        .more-features {
          width: 40%;
        }
      }

      .max-size {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .best-practice {
      .practice {
        .content {
          margin: 2em 1em 2em 1em;
          flex-direction: column;
          align-items: center;

          .img {
            width: 90%;
            //height: 300px;
          }

          .block {
            margin-top: 2.5em;
            margin-left: 0;
            max-width: 90%;
          }
        }
      }
    }

    .media {
      h2 {
        font-size: 2.5em;
        margin-top: 0.5em;
        flex-direction: column;
        align-items: center;
      }

      .place-words {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          position: absolute;
          top: auto;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  @media screen and (min-width: 251px) and (max-width: 479px) {
    .storys {
      .presentation-wrapper {
        display: none;
      }

      .presentation-nav {
        width: 100%;
        height: auto;

        .presentation-wrapper-nav {
          height: auto;
          margin-bottom: 1em;
          width: 95%;

          &.active {
            border-color: hsla(0, 0%, 93%, 1);
          }
        }
      }
    }

    .features {
      .feature {
        width: 245px;
        margin: 2.5em 0;

        .icon {
          font-size: 9em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 220px;
        }
      }

      .max-width {
        .more-features {
          width: 100%;
        }
      }

      .max-size {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .best-practice {
      .practice {
        .content {
          margin: 2em 1em 2em 1em;
          flex-direction: column;
          align-items: center;

          .img {
            width: 100%;
            //height: 250px;
          }

          .block {
            margin-top: 2.5em;
            margin-left: 0;
            max-width: 100%;
          }
        }
      }
    }

    .media {
      h2 {
        font-size: 2em;
        margin-top: 0;
        flex-direction: column;
        align-items: center;
      }

      .place-words {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          position: absolute;
          top: auto;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }

    .how-to {
      .part-one {
        i {
          font-size: 7em;
        }
      }

      .part-two {
        i {
          font-size: 5em;
        }
      }
    }
  }

  @media screen and (max-width: 250px) {
    .storys {
      .presentation-wrapper {
        display: none;
      }

      .presentation-nav {
        width: 100%;
        height: auto;

        .presentation-wrapper-nav {
          height: Auto;
          margin-bottom: 1em;
          width: 95%;

          &.active {
            border-color: hsla(0, 0%, 93%, 1);
          }
        }
      }
    }

    .features {
      .feature {
        width: 95%;
        margin: 2.5em 0;

        .icon {
          font-size: 9em;
          max-height: 160px;

          img {
            vertical-align: top;
          }
        }

        .info {
          height: 250px;
        }
      }

      .max-width {
        .more-features {
          width: 100%;
        }
      }

      .max-size {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .best-practice {
      .practice {
        h3 {
          word-break: break-all;
        }

        .content {
          margin: 2em 1em 2em 1em;
          flex-direction: column;
          align-items: center;

          .img {
            width: 100%;
            //height: 200px;
          }

          .block {
            margin-top: 2.5em;
            margin-left: 0;
            max-width: 100%;
          }
        }
      }
    }

    .media {
      h2 {
        font-size: 1.85em;
        margin-top: 0;
        flex-direction: column;
        align-items: center;
      }

      .place-words {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          position: absolute;
          top: auto;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

}

.footer {
  .column {
    .fa {
      margin-right: 0.5em;
    }
  }
}

.padding {
  padding-top: 3.5em;
  padding-bottom: 5.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.black, .blue, .white {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.max-size {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1140px;

  &.column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }
}

.white {
  width: 100%;
  background-color: white;
  color: black;

  &.line {
    .max-size {
      max-width: 1240px;
    }
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: black;
  }
}

.black {
  width: 100%;
  background-color: black;
  color: white;
}

.blue {
  width: 100%;
  background-color: #3f80cf;
  color: white;
}

.p-row {
  padding: 0 0 0.5em 0;
  width: 100%;
}

.icon-wrapper {
  width: 100%;
  background-color: transparent;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .max-size {
    justify-content: center;
    align-items: center;

    i {
      color: white;
      font-size: 4em;
    }
  }
}

.price-page {
  .btn-group {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }

  .info {
    padding-bottom: 0;

    .text-center {
      flex-direction: column;
    }

    h2 {
      text-align: center;
      font-size: 1.9em;
    }
  }

  .price-table-wrapper {
    .price-table {
      border-collapse: collapse;

      td, th {
        //border: 1px solid #dddddd;
        border: none;
        text-align: center;
        padding: 8px;
        width: 160px;

        &:first-child, &.first-element {
          text-align: left;
          width: 50%;
        }

        a {
          &.btn {
            color: white;

            &:hover {
              color: white;
            }
          }

          color: black;
          width: 100%;
          display: flex;

          &:hover {
            text-decoration: none;
            color: #555;
          }
        }

        &.first-element {
          font-size: 1.2em;
        }
      }

      td:nth-child(2), td:nth-child(3), td:nth-child(4) {
        border-left: solid 1px #ddd;
      }

      th:nth-child(2), th:nth-child(3), th:nth-child(4) {
        border-left: solid 1px #ddd;
      }

      tr:nth-child(even) {
        background-color: #fff;
      }

      tr:nth-child(odd) {
        background-color: #eee;
      }

      tr:last-child {
        border-bottom: none;
      }

      tr {
        border-bottom: solid 1px #ddd;

        td:first-child, .first-element {
          padding-left: 2em;
          font-weight: bold;
        }
      }

      tr:first-child {
        background-color: #fff;
      }

      tr:last-child {
        background-color: #fff;
      }

      tr.price-row {
        border-bottom: none;
        background-color: #fff;
        font-weight: bold;

        .smaller {
          font-size: 0.85em;
        }
      }

      tr.btn-row {
        a {
          display: flex;
          justify-content: center;
        }

        button {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .btn-ghost {
          border: solid 1px #333;
          color: #333;
          font-weight: normal;

          &:hover {
            color: black;
            background-color: #eee;
            border-color: black;
          }
        }

        td {
          padding-top: 0;
        }
      }

      .info-icon {
        font-size: 1rem;
        margin-left: 0.5rem;
      }

      .infinity {
        font-size: 1.8em;
        line-height: 0.5;
        vertical-align: middle;
      }

      sup {
        top: -1em;

        &.down {
          top: -0.5em;
        }
      }
    }

    @media (min-width: 768px) {
    }
    @media (max-width: 767px) {
      .switch-on {
      }

      .switch-off {
        display: none;
      }
    }
  }

  .max-size {
    align-items: center;
    justify-content: center;

    .btn-group {
      margin: 1rem;
    }
  }

  .list-info {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 940px;
    font-size: 0.8em;
    font-weight: bold;
    color: #777;
    margin: 1em 0 1.5em 0;
  }

  .footer {
    .max-size {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.terms-page {
  h3, a {
    word-break: break-all;
  }

  p {
    padding-left: 0.5em;
  }

}

.features-page {
  .blink {
    transition: all ease 350ms;
    background-color: hsla(213, 60%, 53%, 0.35);
    border-radius: 1em;
  }

  .max-size {
    flex-wrap: wrap;
    justify-content: center;

    .try-btn {
      font-size: 1.5em;
    }

    &.try-it-text {
      margin-top: 2em;
      font-size: 1.5em;
      text-align: center;
    }
  }

  .feature {
    border-radius: 1em;
    transition: all ease 350ms;
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 30px;

    @media ( min-width: 480px) {
      width: 300px;
    }

    @media (max-width: 300px) {
      height: 600px;
    }

    &#SLIDE_BASED {
      .info-icon {
        img {
          max-height: 44%;
        }

        .fa-plus {
          font-size: 4rem;
          margin: 1rem;
        }
      }
    }

    &#INTEGRATED {
      .info-icon {
        flex-wrap: wrap;

        .glyphicon {
          font-size: 6rem;
        }

        .fa {
          font-size: 3rem;
          margin: 1rem;
        }

        span {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -4rem;

          img {
            max-width: 130px;
          }
        }
      }
    }

    &#COMBINED_EXPORT {
      position: relative;

      .info-icon {
        position: relative;
      }

      .fa-download {
        font-size: 4rem;
        margin-top: 90px;
        margin-left: -10px;
      }
    }

    &#WEB_APP {
      .info-icon {
        align-items: flex-end;
        padding-bottom: 2rem;

        .fa-mobile {
          font-size: 1em;
          margin: 1rem 1rem 1rem 2rem;
        }

        .fa-tablet {
          font-size: 0.95em;
          margin: 1rem;
        }

        .fa-laptop {
          font-size: 1.35em;
        }
      }
    }

    &#MULTI_SCREEN {
      .info-icon {
        .glyphicon.glyphicon-blackboard {
          font-size: 5rem;
          align-self: flex-start;
          margin-top: 2.5rem;
          margin-left: -1.1rem;
          margin-right: -1.1rem;
        }

        .fa.fa-laptop {
          align-self: flex-end;
          margin-bottom: 2rem;
          font-size: 9rem;
        }
      }
    }

    &#POWERPOINT {
      position: relative;

      .info-icon {
        position: relative;
      }

      .fa-upload {
        font-size: 4rem;
        margin-top: 105px;
        margin-left: -5px;
      }
    }

    &#EXPORT {
      position: relative;

      .info-icon {
        position: relative;
      }

      .fa-download {
        font-size: 4rem;
        margin-top: 90px;
        margin-left: -10px;
      }
    }

    &#PORTAL {
      .info-icon {
        .fa-list-alt {
          font-size: 9rem;
        }
      }
    }

    &#PRETTY_URL {
      .info-icon {
        .fa-external-link {
          margin-left: 2rem;
          font-size: 8rem;
        }
      }
    }

    &#MULTI_ANSWER {
      .info-icon {
        .fa-comments-o {
          font-size: 8rem;
        }
      }
    }

    &#FOLLOW_ME {
      .info-icon {
        .fa-chevron-left {
          font-size: 2.5rem;
          margin-right: 1rem;
        }

        .fa-picture-o {
          font-size: 8rem;
        }

        .fa-chevron-right {
          font-size: 2.5rem;
          margin-left: 1rem;
        }
      }
    }

    &#REUPLOAD {
      .info-icon {
        .fa {
          font-size: 8rem;
        }

        .fa-refresh {
          font-size: 4.5rem;
          margin-top: 80px;
          margin-left: -22px;
        }
      }
    }

    &#ORGANISATION {
      .info-icon {
        .fa {
          margin: 1rem;
        }
      }
    }

    &#OFFLINE_FALLBACK {
      .info-icon {
        .fa-exclamation-triangle {
          font-size: 4rem;
          margin-left: -80px;
          margin-top: -40px;
        }
      }
    }

    .info-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 150px;
      font-size: 5em;

      .img {
        max-height: 100%;
        max-width: 110px;
      }

    }

    .info-text {
      width: 100%;
      min-height: 150px;
      text-align: center;

      div:first-child {
        font-weight: bold;
      }
    }
  }
}

.contact-page {
  min-height: ~'calc(100vh - 70px)';
  background: black;

  .white {
    .max-size {
      max-width: 860px;

      .fa-envelope {
        margin-right: 0.25em;
      }

      .form-control {
        border-radius: @border-radius-base;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: @border-radius-base;
      }

      .dropdown-icon {
        display: block;
        width: 10px;
        height: 10px;
        border-bottom: solid 2px lightgray;
        border-left: solid 2px lightgray;
        transform: rotateZ(-45deg);
        z-index: 4;
        position: absolute;
        right: 16px;
        top: 9px;
        pointer-events: none;
      }

      .btn-group {
        float: right;
      }

      .text-area-label {
        width: 100%;
        border: solid 1px @input-group-addon-border-color;
        border-top-left-radius: @border-radius-base;
        border-top-right-radius: @border-radius-base;
        padding: 0.5em;
        background-color: @input-group-addon-bg;
        color: @input-color;
      }

      .contact-line {
        margin-bottom: 1em;
      }
    }
  }

  .info {
    margin-left: 0.5em;
  }

  .label {
    display: inline-block;
    width: 225px;
  }

  .input-group {
    margin: 0 0 0.5em 0;
    width: 100%;

    .input-group-addon {
      width: 100px;
      text-align: right;
    }

    .form-control {
      width: 100%;

      &.error {
        border: solid 1px red;
      }
    }
  }

  .text-area {
    &.form-control {
      min-height: 342px;
    }

    &.error {
      border: solid 1px red;
    }
  }

  .error {
    color: red;
  }
}

.about-page {
  .persona-img {
    margin-left: -1px;
  }

  .mail-img {
    margin: 0.5em -1px;
    cursor: pointer;
  }

  .about-link {
    color: #2a6496;
    text-decoration: underline;
  }

  .padding.white {
    min-height: ~'calc(100vh - 235px - 70px)';
    justify-content: flex-start;
  }
}

.faq-page {
}

.privacy-page {
  h3, a {
    word-break: break-all;
  }

  p {
    padding-left: 0.5em;
  }
}

@media screen and (min-width: 992px) {
  .btn-wrapper {
    width: 100%;
    background-color: transparent;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      justify-content: center;
      align-items: center;

      .btn-group {
        margin-top: -0.25em;

        .btn {
          letter-spacing: 0.05em;
          font-size: 1.7em;
          padding: 0.2em 1.2em;
        }
      }
    }
  }

  .footer {
    padding-top: 2em;
    padding-bottom: 4em;
    display: flex;
    justify-content: center;
    align-items: center;

    .column {
      flex-grow: 1;
      max-width: 25%;

      h2 {
        margin-bottom: 0.2em;
        text-align: left;
        padding: 0;

        .logo-wrapper {
          width: 122px;
          height: 46px;
          display: inline-block;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      margin: 0.5em 3em;

      h3 {
        font-size: 1.5em;
        white-space: nowrap;
      }

      p {
        white-space: nowrap;
      }

      ul {
        padding-left: 1em;
        list-style: none;

        li {
          list-style: none;
          margin: 0.5em 0;

          a {
            .clickable;
            font-size: 1em;
            color: white;
            text-decoration: none;

            &:active {
              color: white;
              text-decoration: none;
            }

            &:hover {
              color: lightgrey;
            }
          }

          .fa-facebook-official, .fa-twitter, .fa-instagram {
            font-size: 1.3em;
          }
        }
      }

      &.three {
        // min-width: 440px;
      }

      li {
        a {
          display: flex;
          flex-wrap: nowrap;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .btn-wrapper {
    width: 100%;
    background-color: transparent;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      justify-content: center;
      align-items: center;

      .btn-group {
        margin-top: -0.25em;

        .btn {
          letter-spacing: 0.05em;
          font-size: 1.7em;
          padding: 0.2em 1.2em;
        }
      }
    }
  }

  .footer {
    padding-top: 2em;
    padding-bottom: 4em;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .column {
      &:first-child {
        min-width: 170px;
        flex-shrink: 1;
        width: 150px;
      }

      &:nth-child(2) {
        flex-shrink: 1;
        min-width: 120px;
      }

      min-width: 200px;

      h2 {
        margin-bottom: 0.2em;
        text-align: left;
        padding: 0;

        .logo-wrapper {
          width: 122px;
          height: 46px;
          display: inline-block;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      margin: 0.5em 3em;

      h3 {
        font-size: 1.5em;
      }

      ul {
        padding-left: 1em;
        list-style: none;

        li {
          list-style: none;
          margin: 0.5em 0;

          a {
            .clickable;
            font-size: 1em;
            color: white;
            text-decoration: none;

            &:active {
              color: white;
              text-decoration: none;
            }

            &:hover {
              color: lightgrey;
            }
          }

          .fa-facebook-official, .fa-twitter, .fa-instagram {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .btn-wrapper {
    width: 100%;
    background-color: transparent;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      justify-content: center;
      align-items: center;

      .btn-group {
        margin-top: -0.25em;

        .btn {
          letter-spacing: 0.05em;
          font-size: 1.2em;
          padding: 0.2em 1.2em;
        }
      }
    }
  }

  .footer {
    padding-top: 2em;
    padding-bottom: 4em;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .column {
      &:first-child {
        min-width: 170px;
        flex-shrink: 1;
        width: 150px;
      }

      &:nth-child(2) {
        flex-shrink: 1;
        min-width: 120px;
      }

      min-width: 150px;

      h2 {
        margin-bottom: 0.2em;
        text-align: left;
        padding: 0;

        .logo-wrapper {
          width: 122px;
          height: 46px;
          display: inline-block;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      margin: 0.5em 3em;

      h3 {
        font-size: 1.5em;
      }

      ul {
        padding-left: 1em;
        list-style: none;

        li {
          list-style: none;
          margin: 0.5em 0;

          a {
            .clickable;
            font-size: 1em;
            color: white;
            text-decoration: none;

            &:active {
              color: white;
              text-decoration: none;
            }

            &:hover {
              color: lightgrey;
            }
          }

          .fa-facebook-official, .fa-twitter, .fa-instagram {
            font-size: 1.3em;
          }
        }
      }
    }
  }

  .features-page {
    .max-size {
      &.try-it-text {
        font-size: 1.25em;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .price-page, .terms-page, .features-page, .contact-page, .about-page, .faq-page, .privacy-page {
    margin-top: -20px;
  }
}

@media screen and (max-width: 479px) {
  .btn-wrapper {
    width: 100%;
    background-color: transparent;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      justify-content: center;
      align-items: center;

      .btn-group {
        margin-top: -0.25em;

        .btn {
          letter-spacing: 0.05em;
          font-size: 1.2em;
          padding: 0.2em 1.2em;
        }
      }
    }
  }

  .footer {
    padding-top: 2em;
    padding-bottom: 4em;
    display: flex;
    justify-content: center;
    align-items: center;

    .max-size {
      flex-direction: column;
    }

    .column {
      h2 {
        margin-bottom: 0.2em;
        text-align: left;
        padding: 0;

        .logo-wrapper {
          width: 122px;
          height: 46px;
          display: inline-block;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }

      margin: 0.5em 3em;

      h3 {
        font-size: 1.5em;
        word-break: break-all;
      }

      ul {
        padding-left: 1em;
        list-style: none;

        li {
          list-style: none;
          margin: 0.5em 0;

          a {
            .clickable;
            font-size: 1em;
            color: white;
            text-decoration: none;

            &:active {
              color: white;
              text-decoration: none;
            }

            &:hover {
              color: lightgrey;
            }
          }

          .fa-facebook-official, .fa-twitter, .fa-instagram {
            font-size: 1.3em;
          }
        }
      }
    }
  }

  .features-page {
    .max-size {
      &.try-it-text {
        font-size: 1.25em;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .contact-page {
    .white {
      .max-size {
        .contact-line {
          .btn-group {
            width: 100%;

            .btn {
              float: right;
            }
          }
        }
      }
    }
  }

  .price-page, .terms-page, .features-page, .contact-page, .about-page, .faq-page, .privacy-page {
    margin-top: -20px;
  }
}

@media screen and (max-width: 640px) {
  .features-page {
    .feature {
      margin: 10px 0;
    }
  }
}