/**
*   All Variables for Timer Directive
*
*/

@prTbWidth: 200px;
@prTbBackgroundColor: hsla(hue(@prColor), 10%, 83%, 0.9);
@prTbFontColor: white;
@prTbFontSize: 5em;

@prTbBorderType: solid;
@prTbBorderWidth: 2px;
@prTbBorderColor: @prColor;

@prTbFontSizeScale: 0.4em;

.time-bar {
  text-align: center;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  width: @prTbWidth;
  background-color: @prTbBackgroundColor;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  color: @prTbFontColor;
  font-size: @prTbFontSize;
  border-left: @prTbBorderType @prTbBorderWidth @prColor;
  .timer {
    font-size: @prTbFontSizeScale;
  }
  .countdown {
    font-size: @prTbFontSizeScale;
  }
}