@sliderStartHeight: 6px;
@sliderEndHeight: 8px;
@sliderStartEndDiff: @sliderEndHeight - @sliderStartHeight;
@sliderMarginTop: @sliderStartEndDiff / 2;

@sliderStartThumbWidth: 20px;
@sliderEndThumbHeight: 22px;

@sliderTransitionTime: 0.3s;
@sliderTransitionEffect: ease;
@sliderTransition: all @sliderTransitionTime @sliderTransitionEffect;

@sliderButtonMarginTop: @sliderEndThumbHeight - @sliderStartHeight;

@sliderPreviewHeight: 250px;
@sliderPreviewBottom: 20px;
@sliderPreviewOpacity: 0.9;
@sliderPreviewValBG: hsla(hue(@prColor), 30%, 95%, 1);

@sliderOpenHeight: 33px;
@sliderOpenOpacity: 1;
@sliderOpenBGColor: hsla(hue(@prColor), 30%, 95%, 0.75);

@sliderLengthColor: hsla(hue(@prColor), 50%, 50%, 0.9);

@sliderTextColorPhase1: hsla(hue(@prColor), 50%, 50%, 0.7);
@sliderTextColorPhase1Active: hsla(hue(@prColor), 60%, 30%, 0.8);
@sliderTextColorPhase2: hsla(hue(@prColor), 50%, 50%, 1);
@sliderTextColorPhase2Active: hsla(hue(@prColor), 60%, 30%, 1);

@sliderForemostColor: hsla(hue(@prColor)-193, 50%, 50%, 0.9);
@sliderForemostBGColor: hsla(hue(@prColor), 8%, 65%, 0.9);
@sliderForemostColorPhase2: hsla(hue(@prColor)-193, 50%, 50%, 1);
@sliderForemostBGColorPhase2: hsla(hue(@prColor), 10%, 50%, 1);

// Mixins
.pr-track {
  transition: @sliderTransition;
  position: absolute;
  margin-top: 1px;
  height: @sliderStartHeight;
  background: transparent;

  outline: none;
  opacity: 0;
}
.pr-thumb{
  width: @sliderStartThumbWidth;
  height: @sliderStartHeight+2px;
  cursor: pointer;
  border-radius: 0;
  border:none;
  background: transparent;
}
.pr-arrow(@length){
  content: ' ';
  display: inline-block;
  width: @length;
  height: @length;
  position: relative;
  transform: rotate(45deg);
}

// Style Code For Slide Controls
slide-controls {

  position: absolute;
  bottom: 0;
  left: 0;

  transition: @sliderTransition;

  width: 100%;
  height: @sliderStartHeight;

  opacity: 0.7;

  .slider {
    &.hidden{
      display: none;
    }

    position: relative;
    width: 100%;

    z-index: 3;

    //Range Styling
    input[type="range"] {

      -webkit-appearance: none;
      .pr-track;

      &:focus {  outline: none;  }

      &::-ms-track {
        .pr-track;
        border-color: transparent;
        color: transparent;
      }

      &::-moz-range-track{  .pr-track;  }

      &::-webkit-slider-thumb {
        .pr-thumb;
        -webkit-appearance: none;
      }

      &::-ms-thumb {  .pr-thumb;  }
      &::-moz-range-thumb{  .pr-thumb;  }
      &::-ms-tooltip {  display: none;  }
    }

    #speaker-slide {
      @scSpeakerThumbSize: 3px;
      &::-webkit-slider-thumb{
        width: @sliderStartThumbWidth;
        height: @sliderEndThumbHeight;
        background-color: transparent;

        position: relative;

        &:before {
          .pr-arrow(@scSpeakerThumbSize);
          border-bottom: solid @scSpeakerThumbSize black;
          border-right: solid @scSpeakerThumbSize black;

          top: -1 * ( @sliderEndThumbHeight / 2 + @scSpeakerThumbSize + 4); // ToDo Check where the 4 comes from
          left: 2 * @scSpeakerThumbSize + 1;
        }
        &:after {
          .pr-arrow(@scSpeakerThumbSize);
          border-top: solid @scSpeakerThumbSize black;
          border-left: solid @scSpeakerThumbSize black;

          bottom: -1 * ( @sliderEndThumbHeight / 2 +  @scSpeakerThumbSize + 2); // ToDo Check where the 2 comes from
          right: -1px;
        }
      }
    }
    .current-slide-length {
      transition: @sliderTransition;
      width: 100%;
      position: absolute;
      margin-top: 1px;

      -webkit-appearance: none;
      appearance: none;
      height: @sliderStartHeight;
      background: none;
      border:none;

      color: @sliderLengthColor;

      &::-webkit-progress-bar {
        background: none;
      }
      &::-webkit-progress-value{
        background-color: @sliderLengthColor;
      }
      &::-moz-progress-bar {
        background-color: @sliderLengthColor;
      }

    }
    .current-slide-output {
      transition: opacity @sliderTransitionTime @sliderTransitionEffect;
      border-radius: 2px;
      border: 1px solid white;

      height: @sliderEndThumbHeight + 2px;

      position: absolute;
      top:  -1 * ( @sliderEndThumbHeight / 2 ) + ( @sliderStartHeight / 2 ) ;

      background-color: hsla(hue(@prColor), 50%, 50%, 1);
      color: white;

      padding: 0 2px;

      visibility: hidden;
      opacity: 0;

      pointer-events: none;

      white-space: nowrap;

      div {
        display: inline-flex;
        justify-content: center;

        border: solid 1px transparent;

        position: relative;
        top: -2px;
        height: @sliderEndThumbHeight;
      }

      .left-arrow, .right-arrow {
        font-size: 14px;
      }
      .number {
        font-size: 16px;
        pointer-events: none;
        cursor: pointer;
        width: @sliderStartThumbWidth;
      }

    }
    .foremost-slide {
      transition: @sliderTransition;
      width: 100%;
      position: absolute;
      margin-top: 1px;

      -webkit-appearance: none;
      appearance: none;
      height: @sliderStartHeight;
      background-color: @sliderForemostBGColor;
      border: none;
      color: @sliderForemostColor;

      &::-webkit-progress-bar {
        background: none;
      }
      &::-webkit-progress-value{
        background-color: @sliderForemostColor;
      }
      &::-moz-progress-bar{
        background-color: @sliderForemostColor;
      }
    }
    .speaker-slide {
      position: absolute;
    }
    .preview {

      height: @sliderPreviewHeight;
      padding: 5px;

      transition: all 0.1s linear;
      border-radius: 3px;

      background-color: black;

      position: absolute;
      bottom: @sliderPreviewBottom;

      visibility: hidden;
      opacity: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      .preview-img {
        max-width: 100%;
        max-height: 100%;
        background-color: white;
      }

      &.show-prev {
        visibility: visible;
        opacity: @sliderPreviewOpacity;
      }

      #hover-val{
        color: hsla(hue(@prColor), 50%, 50%, 1);

        font-size: 14px;
        font-weight: bold;

        position: absolute;
        bottom: 18px;
        right: 18px;
        border-radius: 10px;
        padding: 2px;
        background-color: @sliderPreviewValBG;

        box-shadow: 0 0 6px 4px @sliderPreviewValBG;
      }

    }
  }

  .pos {
    transition: @sliderTransition;

    display: flex;
    align-items: center;

    visibility: hidden;
    opacity: 0;

    position: absolute;
    bottom: 0;
    left: 5px;

    font-weight: bold;

    height: 100%;

    font-size: 16px;
    padding-top: 15px;

    z-index: 1;

    .speaker-slide {
      text-decoration: underline;
    }

    span {
      margin: 0 5px;
    }

    &.hidden{
      display: none;
    }
  }

  .controls {
    visibility: hidden;
    opacity: 0;
    transition: @sliderTransition;
    height: 40px - 8px;
    margin: 0 10px 0 10px;

    z-index: 4;

    .hidden {
      display: none;
    }

    .btn {
      background: none;
      color: @sliderTextColorPhase1;
      font-weight: bold;
      border-radius: 0;
      padding: 5px 14px;

      &:focus {
        outline: none;
        color: @sliderTextColorPhase1Active;
      }

      &.active {
        box-shadow: none;
        color: @sliderTextColorPhase1Active;
        border-bottom: 2px solid @sliderTextColorPhase1Active;
      }

    }

  }

  .beamer-header{
    display: none;
  }
}

// MouseEvents
// Phase 1
slide-controls{
  &.show-slider {
    height: @sliderOpenHeight + @sliderButtonMarginTop;
    opacity: @sliderOpenOpacity;

    .slider {
      input[type="range"] {
        opacity: @sliderOpenOpacity;
        height: @sliderEndHeight;
        margin-top: 0;
        &::-moz-range-track{
          opacity: @sliderOpenOpacity;
          height: @sliderEndHeight;
          margin-top: 0;
        }
      }
      .current-slide-length {
        height: @sliderEndHeight;
        margin-top: 0;
      }
      .foremost-slide {
        height: @sliderEndHeight;
        margin-top: 0;
      }
      #speaker-slide {
        opacity: 0.7;
      }

    }

    .pos {
      color: @sliderTextColorPhase1;
      visibility: visible;
      opacity: @sliderOpenOpacity;
    }

    .controls {
      visibility: visible;
      opacity: 1;

      margin-top: @sliderButtonMarginTop;
    }
  }
}
// Phase 2
slide-controls{
  &.show-slider{
    &.show-button {
      background-color: @sliderOpenBGColor;

      .slider {
        input[type="range"] {
          margin-top: 0;
          &::-webkit-slider-thumb {
            background-color: transparent;
            width: @sliderStartThumbWidth;
            height: @sliderEndThumbHeight;
          }
        }
        .current-slide-length {
          color: @sliderTextColorPhase2;
          &::-webkit-progress-value {
            background-color: @sliderTextColorPhase2;
          }
          &::-moz-progress-bar {
            background-color: @sliderTextColorPhase2;
          }

        }
        .foremost-slide {
          background-color: @sliderForemostBGColorPhase2;
          color: @sliderForemostColorPhase2;
          &::-webkit-progress-value {
            background-color: @sliderForemostColorPhase2;
          }
          &::-moz-progress-bar{
            background-color: @sliderForemostColorPhase2;
          }
        }
        .current-slide-output {
          visibility: visible;
          opacity: 1;
        }
        #speaker-slide {
          opacity: 1;
        }
      }

      .pos {
        color: @sliderTextColorPhase2;
      }

      .controls {
        .btn {
          color: @sliderTextColorPhase2;

          &:focus {
            color: @sliderTextColorPhase2Active;
          }

          &.active {
            color: @sliderTextColorPhase2Active;
            border-bottom: solid 2px @sliderTextColorPhase2Active;
          }
        }
      }
    }
  }
}

// Special Content
.beamer-content {
  slide-controls {
    opacity: 1;
    left: auto;
    bottom: 6vh;
    right: 2vw;

    width: auto;
    height: auto;

    border-radius: 4px;
    .slider {
      display: none;
    }
    .pos {
      display: none;
    }
    .controls {
      margin: 2px;
      .btn-group {
        margin: 0;
      }
    }

    .beamer-header {
      position: fixed;
      left: 0;

      z-index: 100;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 8vh;

      font-size: 6vh;
      color: white;

      background-color: hsla(hue(@prColor), 32%, 34%, 0.8);

      &.closed {
        display: none;
      }
      .plugins {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        font-size: 4vh;

        display: flex;
        align-items: center;

        color: lightgrey;

        .symbol {
          &:hover {
            color: white;
          }

          &:first-child {
            margin: 0 20px;
          }
        }

      }
      .url{
        flex-grow: 1;
        text-align: center;
      }
      .cr-message{
        font-size: 0.7em;
        margin-right: 2em;
      }
      &.top {
        top: 0;
      }
      &.bottom {
        bottom: 0;
      }
    }


    &.show-slider{
      background-color: hsla(hue(@prColor), 30%, 95%, 0.25);
      .contorls{
        .btn{
          color: hsla(hue(@prColor), 50%, 50%, 0.6);

          &:focus {
            color: hsla(hue(@prColor), 60%, 30%, 0.7);
          }

          &.active {
            color: hsla(hue(@prColor), 60%, 30%, 0.6);
            border-bottom: 2px solid hsla(hue(@prColor), 60%, 30%, 0.6);
          }
        }
      }

      &.show-button{
        background-color: hsla(hue(@prColor), 30%, 95%, 0.85);
      }
    }

  }
}

