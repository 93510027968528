/**
*   All Variables for Note
*   prifixes pr = presentr, No = Note
*/

@fontSize: 14px;
@lineHeight: 1.42857143;
@prNoTextLines: 2;

@prNoBgColorOdd: @prColorOdd;
@prNoFadeColorOddStart: hsla(hue(@prColor), 32%, 91%, 0);
@prNoFadeColorOddEnd: hsla(hue(@prColor), 32%, 91%, 1);

@prNoBgColorEven: @prColorEven;
@prNoFadeColorEvenStart: hsla(hue(@prColor), 37%, 83%, 0);
@prNoFadeColorEvenEnd: hsla(hue(@prColor), 37%, 83%, 1);

@prNoFadeOutEffect: 70%;

@prNoBgColor: @prColorBackgroundContent;
@prNoAbstand: 1px;
@prNoPadding: 0px;

@prNoColorWidth: 17px;

@prNoDdBorder: none;
@prNoDbMargin: 4px 0 0 0;
@prNoDbLiMargin: 0 0 0 0;
@prNoDbLiPadding: 0 0 0 0;
@prNoDbAPadding: 0 5px 0 5px;
@prNoDbSpanMargin: 0 5px 0 5px;

@prNoColorSpeaker: @btn-warning-bg;
@prNoColorPublic: @btn-primary-bg;
@prNoColorPrivate: @btn-success-bg;

@prNoMainPadding: 0 0 0 0;
/* Top right bottom left*/
@prNoMainMarginLeft: 5px;
@prNoMainMargin: 0 0 0 (@prNoColorWidth + @prNoMainMarginLeft);
/* Top right bottom left */
@prNoMainWidthBuffer: @prNoColorWidth + @prNoMainMarginLeft;
@prNoMainWidth: calc(~'100% - (@{prNoMainWidthBuffer})');
//Sollte schon fast überall unterstützt sein (außer Opera Mini und UC for Android)

@prNoContPadding: 5px 15px 10px 0;
@prNoContHeight: (@fontSize*@lineHeight)*@prNoTextLines;

@prNoFadeOutLength: 30%;
@prNoFadeOutAfterHeight: (@fontSize*@lineHeight);

@prNoEditWidth: 100%;

@prNoVoteColor: dimgray;
@prNoVoteColorOwn: hsla(hue(@prColor), 0%, 70%, 1);
@prNoVoteColorHover: hsla(hue(@prColor), 0%, 0%, 1);
@prNoVoteColorVotedUp: @btn-primary-bg;
@prNoVoteColorVotedDown: @btn-danger-bg;
@prNoVoteUpMargin: 0 10px 0 0;
@prNoVoteDownMargin: 0 0 0 0;

@prNoCosFontSize: 12px;
@prNoCosMargin: 0 0 0 0;

@prNoCoPadding: 0 5px 5px 5px;
@prNoCoMargin: 5px 20px 0 25px;

@prNoAddCoPadding: @prNoCoPadding;
@prNoAddCoMargin: @prNoCoMargin;

@prNoButtonFontSize: @prNoCosFontSize;
@prNoButtonPadding: 4px 9px 4px 9px;

@prNoCoHeaderColor: #777;

@prNoCoAddWidth: 100%;
@prNoCoAddFontSize: @prNoCosFontSize;
@prNoCoAddLines: 3;
@prNoCoAddMargin: 0 0 2px 0;
@prNoAddCoBottomMargin: 0 0 10px 0;

@prNoCoCloserMargin: 0 0 0 25px;

@prNoHeaderFontWeight: bold;
@prNoHeaderTimeStempFontSize: 80%;

@prNoCoOpenerMargin: 0 0 0 20px;
@prNoCoOpBgColor: hsla(220, 30%, 75%, 1);
//hue(@prColor) 200
@prNoCoOpBorder: hidden;
@prNoCoOpBorderRadius: 12px;
@prNoCoOpPadding: 3px 6px 3px 6px;

// Select the node Element for Odd and Even Colors and for the Fade out Color because of Odd and Even

note {

  &:nth-child(odd) .note {

    background-color: @prNoBgColorOdd;

    .fade-out:after {

      background: linear-gradient(to right, @prNoFadeColorOddStart, @prNoFadeColorOddEnd @prNoFadeOutEffect);

    }
  }

  &:nth-child(even) .note {

    background-color: @prNoBgColorEven;

    .fade-out:after {

      background: linear-gradient(to right, @prNoFadeColorEvenStart, @prNoFadeColorEvenEnd @prNoFadeOutEffect);

    }
  }
}

.note {

  background-color: @prNoBgColor;
  margin-bottom: @prNoAbstand;
  padding: @prNoPadding;

  .note-color {

    position: absolute;
    top: 0;
    bottom: 0;
    width: @prNoColorWidth;
    font-size: @prNoColorWidth + 1px;

    .note-menu {

      .glyphicon;

      .glyphicon-option-vertical;
    }

    .dropdown {

      margin: @prNoDbMargin;
      border-bottom: @prNoDdBorder;
      ul {
        li {
          margin: @prNoDbLiMargin;
          padding: @prNoDbLiPadding;
          a {
            padding: @prNoDbAPadding;
          }

          span {
            margin: @prNoDbSpanMargin;
          }
        }
      }

    }

    &.note-speaker {

      background-color: @prNoColorSpeaker;

    }

    &.note-public {

      background-color: @prNoColorPublic;

    }

    &.note-private {

      background-color: @prNoColorPrivate;

    }
  }

  .note-main {

    padding: @prNoMainPadding;
    margin: @prNoMainMargin;
    width: @prNoMainWidth;

    .note-cont {

      padding: @prNoContPadding;

      .note-cont-text {

        height: @prNoContHeight;
        overflow: hidden;

        &.full-cont {

          height: auto;
          min-height: @prNoContHeight;

        }

        &.fade-out {

          position: relative;
          height: @prNoContHeight;

        }

        &.fade-out:after {

          content: "";
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
          width: @prNoFadeOutLength;
          height: @prNoFadeOutAfterHeight;

        }
      }

      .note-cont-edit {

        width: @prNoEditWidth;

      }
    }

    .note-vote {
      display: inline-block;
      float: right;
      color: @prNoVoteColor;

      .note-vote-up-glyph,
      .note-vote-down-glyph {
        color: @prNoVoteColorOwn;
      }

      .clickable {

        .note-vote-up-glyph, .note-vote-down-glyph {
          color: @prNoVoteColor;
        }

        &.note-vote-up:hover, &.note-vote-down:hover {
          color: @prNoVoteColorHover;
          font-weight: bold;
          .note-vote-up-glyph, .note-vote-down-glyph {
            color: @prNoVoteColorHover;
            font-weight: bold;
          }
        }
      }

      .note-vote-up {
        display: inline-block;
        margin: @prNoVoteUpMargin;
        &.voted {
          color: @prNoVoteColorVotedUp;
          .note-vote-up-glyph {
            color: @prNoVoteColorVotedUp;
          }
          &:hover {
            &.clickable {
              color: @prNoVoteColorVotedUp;
              .note-vote-up-glyph {
                color: @prNoVoteColorVotedUp;
              }
            }
          }
        }
      }
      .note-vote-down {
        display: inline-block;
        margin: @prNoVoteDownMargin;
        &.voted {
          color: @prNoVoteColorVotedDown;
          .note-vote-down-glyph {
            color: @prNoVoteColorVotedDown;
          }
          &:hover {
            &.clickable {
              color: @prNoVoteColorVotedDown;
              .note-vote-down-glyph {
                color: @prNoVoteColorVotedDown;
              }
            }
          }
        }
      }
    }
  }

  .note-comments {

    font-size: @prNoCosFontSize;
    margin: @prNoCosMargin;

    .note-comment {

      margin: @prNoCoMargin;
      padding: @prNoCoPadding;

    }

    .note-add-comment {

      margin: @prNoAddCoMargin;
      padding: @prNoAddCoPadding;

      .btn {

        font-size: @prNoButtonFontSize;
        padding: @prNoButtonPadding;

      }

      .note-header {

        color: @prNoCoHeaderColor;

      }

      .bottom-space {

        margin: @prNoAddCoBottomMargin;

      }
    }

    .comment-add {

      width: @prNoCoAddWidth;
      font-size: @prNoCoAddFontSize;
      min-height: (@prNoCoAddFontSize * @lineHeight * @prNoCoAddLines)+9px; //+9px heck for border Problems.
      margin: @prNoCoAddMargin;

    }

    .comment-closer {

      margin: @prNoCoCloserMargin;

    }
  }

  .note-header {

    .glyphicon-share-alt {
      transform: scale(-1, 1);
    }

    .note-header-usrname {

      font-weight: @prNoHeaderFontWeight;

    }

    .note-header-ts {

      font-size: @prNoHeaderTimeStempFontSize;

    }

    .comment-opener {
      margin: @prNoCoOpenerMargin;
      border-radius: @prNoCoOpBorderRadius;
      padding: @prNoCoOpPadding;

      &.background {
        border: @prNoCoOpBorder;
        background-color: @prNoCoOpBgColor;
      }

    }
  }
}

// Spezial Note style on the Extended view
.extended {
  .note {
    .note-header {
      font-size: @fontSize;
    }
  }
}

.audience-view {
  .speaker-notes {

    margin: 0 5px 3px 5px;

    border-bottom: solid 1px black;

    .info-header{
      font-size: 16px;
      font-weight: bold;
    }

    .note {
      &:nth-child(odd) {
        background-color: @prNoBgColorOdd;
      }
      &:nth-child(even) {
        background-color: @prNoBgColorEven;
      }
      &:nth-last-child(1){
        margin-bottom: 7px;
      }
      margin-bottom: 2px;
      .note-content {
        height: auto;
        padding: 3px;
        font-size: 16px;
      }
    }
  }
}