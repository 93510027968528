@font-face {
  font-family: 'icomoon';
  src:url('/public/assets/res/fonts/icomoon.eot');
  src:url('/public/assets/res/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
  url('/public/assets/res/fonts/icomoon.woff') format('woff'),
  url('/public/assets/res/fonts/icomoon.ttf') format('truetype'),
  url('/public/assets/res/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-auswahl4:before {
  content: "\e600";
}
.icon-auswahl3:before {
  content: "\e601";
}
.icon-auswahl2:before {
  content: "\e602";
}
